// eslint-disable-next-line no-unused-vars
import React from 'react';
import get from 'lodash/get';
import { PICKLEJAR_DEFAULT_AVATAR_IMAGE, PICKLEJAR_EVENTS_DEFAULT_IMAGE } from '../../../const/ClaimsTheme';

export const RenderImages = props => {
  const isArtistOrBand = get(props, 'band.banner.mediumImage', null);
  const isProfile = get(props, '_misc.banner.medium_image', null);

  return isArtistOrBand || isProfile || PICKLEJAR_EVENTS_DEFAULT_IMAGE;
};

export const RenderAvatar = props => {
  console.log(props);
  const isArtistOrBand = get(props, 'band.avatar.mediumImage', null);
  const isProfile = get(props, '_misc.avatar.medium_image', null);

  return isArtistOrBand || isProfile || PICKLEJAR_DEFAULT_AVATAR_IMAGE;
};

export const RenderClaimsBanner = props => {
  const isArtistOrBand = get(props, '_misc.claim_extra_information._misc.banner.medium_image', null);
  const isProfile = get(props, '_misc.claim_extra_information._misc.banner.medium_image', null);
  return isArtistOrBand || isProfile || PICKLEJAR_EVENTS_DEFAULT_IMAGE;
}

export const RenderClaimsAvatar = props => {
  const isArtistOrBand = get(props, '_misc.claim_extra_information._misc.avatar.medium_image', null);
  const isProfile = get(props, 'avatar.medium_image', null);
  return isArtistOrBand || isProfile || PICKLEJAR_EVENTS_DEFAULT_IMAGE;
};


import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Edit } from '@mui/icons-material';

// Relatives
import ClaimsList from '../../../pages/Claims/ClaimsList';
import { useNavigate } from 'react-router';
import ArtistsList from '../../../pages/Claims/ArtistsList';
import BandList from '../../../pages/Claims/BandsList';

export function ClaimsBody() {
  /**
   * App Contexts & Params
   */
  const navigate = useNavigate();

  const [handleFound, setHandleFound] = useState(null);
  const [artistFound, setArtistFound] = useState(null);
  const [brandFound, setBrandFound] = useState(null);
  const [loading, setLoading] = useState(true);
  const artistsFound = (value) => setArtistFound(value);
  const bandsFound = (value) => setBrandFound(value);
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (artistFound !== null && brandFound !== null) {
      setHandleFound(artistFound > 0 || brandFound > 0);
      setLoading(false);
    }
  }, [artistFound, brandFound]);

  return (
    <Box className={'ClaimsBody'}>
      <Grid
        component="div"
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          disableRipple
          type="button"
          onClick={() => navigate('/claims/create')}
          startIcon={<Edit/>}
          color="primary"
          size="small"
          variant="contained"
        >
          Claim Handle
        </Button>
      </Grid>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Claims</Typography>
        </AccordionSummary>
        <AccordionDetails key="reviseClaimsList">
          {
            <ClaimsList/>
          }
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>My Handles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            <Grid
              container
              spacing={2}
              key="reviseClaimsList-grid"
            >
              <ArtistsList
                artistFound={artistsFound}
                showLoader={false}
                isLoading={loading}
              />
              <BandList
                bandsFound={bandsFound}
                showLoader={false}
                isLoading={loading}
              />
              {
                loading &&
                (
                  <CircularProgress sx={{margin: '1rem auto'}}/>
                )
              }
              {
                handleFound === false &&
                (
                  <Typography component="h2">No handles found</Typography>
                )
              }
            </Grid>
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

import PropTypes from 'prop-types';

export const DepartmentModel = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  _related: PropTypes.array,
  _misc: PropTypes.array,
  _links: PropTypes.array
};

export default DepartmentModel

import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
// Relatives
import HandleCards from './components/HandleCards';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// eslint-disable-next-line
const ArtistsList = props => {
  const { artistFound, showLoader, isLoading } = props;
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  /**
   * App State
   */
  const [artistsList, setArtistsList] = useState([]);
  const [loading, setLoading] = useState(showLoader);
  /**
   * Requests
   */
  const fetchRelatedArtists = async () => {
    if (showLoader) {
      setLoading(true);
    }
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v1/artist/my-artist-registrations`);

    const { success, data } = get(response, 'data', {});

    if (success === true) {
      setArtistsList(data);
    }

    setLoading(false);
    artistFound(data && data.length ? data.length : 0);
  };
  /**
   * Effects
   */
  // Fetch Artists
  useEffect(() => {
    if (artistsList.length === 0) {
      fetchRelatedArtists().catch(err => console.log(err));
    }
  }, [artistsList.length]);

  return (
    <>
      {
        (
          !loading ?
            artistsList.length > 0 &&
            (
              artistsList.map(
                artist =>
                  <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                    key={`artist-${artist.id}`}
                    hidden={isLoading}
                  >
                    <HandleCards data={artist}/>
                  </Grid>
              )
            ) :
            <Grid
              container
              spacing={2}
              key="reviseClaimsList-grid"
              justifyContent="center"
            >
              <CircularProgress/>
            </Grid>
        )
      }
    </>
  );
};

ArtistsList.defaultProps = {
  showLoader: false,
  isLoading: false
};

ArtistsList.propTypes = {
  artistFound: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default ArtistsList;

import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { get } from 'lodash';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
// Relatives
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import HandleCards from './components/HandleCards';

// eslint-disable-next-line
const BandList = props => {
  const { bandsFound, showLoader } = props;
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  // eslint-disable-next-line
  const navigate = useNavigate();
  /**
   * App State
   */
  const [bandsList, setBandsList] = useState([]);
  const [loading, setLoading] = useState(showLoader);
  /**
   * Requests
   */
  const fetchRelatedBands = async () => {
    if (showLoader) {
      setLoading(true);
    }
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v1/band/my-band-registrations`);

    const { success, data } = get(response, 'data', {});

    if (success === true) {
      setBandsList(data);
    }
    setLoading(false);
    bandsFound(data && data.length ? data.length : 0);
  };
  /**
   * Effects
   */
  // Fetch Bands
  useEffect(() => {
    if (bandsList.length === 0) {
      fetchRelatedBands().catch(err => console.log(err));
    }
  }, [bandsList.length]);

  return (
    !loading ?
      bandsList.length > 0 &&
      (
        bandsList.map(
          ({ band }) =>
            <Grid
              item
              lg={4}
              sm={6}
              xs={12}
              key={`band-${band.id}`}
            >
              <HandleCards data={band}/>
            </Grid>
        )
      ) :
      <Grid
        container
        spacing={2}
        key="reviseClaimsList-grid"
        justifyContent="center"
      >
        <CircularProgress/>
      </Grid>
  );
};

BandList.defaultProps = {
  showLoader: false,
  isLoading: false
};

BandList.propTypes = {
  bandsFound: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default BandList;

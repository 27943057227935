import PropTypes from 'prop-types';
import { UserModel } from './UserModel';
import { DepartmentModel } from './DepartmentModel';
import { AttachmentModel } from './AttachmentModel';

export const RelatedModel = {
  id: PropTypes.number,
  is_deleted: PropTypes.bool,
  owner_class: PropTypes.string,
  owner_id: PropTypes.number,
  user_id: PropTypes.number,
  department_id: PropTypes.number,
  topic: PropTypes.string,
  status: PropTypes.string,
  notes: PropTypes.string,
  created_at: PropTypes.number,
  updated_at: PropTypes.number,
  created_by: PropTypes.number,
  updated_by: PropTypes.number,
  _related: {
    user:UserModel,
    department: DepartmentModel,
    ticket_content: {
      id: PropTypes.number,
      id_head: PropTypes.number,
      name_user: PropTypes.string,
      text: PropTypes.string,
      client: false,
      created_by: PropTypes.number,
      created_at: PropTypes.number,
      read_at: PropTypes.number,
      _related: PropTypes.array,
      _misc: {
        attachments: [AttachmentModel]
      },
      _links: PropTypes.array
    }
  },
  _misc: {
    attachments: []
  },
  _links: {
    self: {
      href: PropTypes.string
    }
  }
}

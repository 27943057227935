import PropTypes from 'prop-types';

export const AvatarModel = {
  id: PropTypes.number,
  originalImage: PropTypes.string,
  smallImage: PropTypes.string,
  mediumImage: PropTypes.string,
  largeImage: PropTypes.string
}

export default AvatarModel;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { get } from 'lodash';
// Relatives
import http from '../../services/api/http';
import ClaimsCards from './components/ClaimsCards';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// eslint-disable-next-line
const ClaimsList = props => {
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  /**
   * App State
   */
  const [claimsList, setClaimsList] = useState([]);
  const [reviseClaimsList, setReviseClaimsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const renderClaimsList = (claimsData, actions) => {
    return claimsData.map(
      data => {
        const key = get(data, '_misc.claim_extra_information.entity_id', Math.random());
        return (
          <Grid
            item
            lg={4}
            sm={6}
            xs={12}
            key={key.toString()}
          >
            <ClaimsCards
              data={data}
              actions={actions}
            />
          </Grid>
        );
      }
    );
  };
  /**
   * Api Requests
   */
  const fetchClaims = async () => {
    setLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/ownership-change-request`);

    const { success, output } = get(response, 'data', {});

    if (success === true) {
      const revise = [];
      const claims = [];
      if (output.data) {
        output.data.forEach(item => {
          const relatedTicket = get(item, '_related.ticket', null);
          if (relatedTicket) {
            if (item.status !== 'revise') {
              revise.push(item);
            } else {
              claims.push(item);
            }
          }
        });
        setReviseClaimsList(revise);
        setClaimsList(claims);
      }
    }

    setLoading(false);
  };
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Fetch Claims
  useEffect(() => {
    if (claimsList.length === 0) {
      fetchClaims().catch(err => console.log(err));
    }
  }, []);

  return (
    <>
      {
        !loading &&
        (
          <Grid
            container
            spacing={2}
            key="reviseClaimsList-grid"
          >
            {renderClaimsList(reviseClaimsList, true)}
            <Divider/>
            {renderClaimsList(claimsList)}
          </Grid>
        )
      }
      {
        loading &&
        (
          <Grid
            container
            justifyContent="center"
          >
            <CircularProgress/>
          </Grid>
        )
      }
    </>
  );
};

ClaimsList.defaultProps = {
  claimsList: [],
  actions: false,
  message: ''
};

ClaimsList.propTypes = {
  claimsList: PropTypes.array,
  actions: PropTypes.bool,
  message: PropTypes.string
};

export default ClaimsList;

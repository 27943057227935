/* eslint-disable camelcase */
// Packages
import React, { useState, useEffect, useContext } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Box, Button, Container, List, ListItem, TextField, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

// Relatives
import AddressLookup from "../../../components/AddressLookup";
import { PICKLEJAR_DEFAULT_EDIT_ICON } from "../../../const/ClaimsTheme";
import UserProfileInformation from "../UserProfileInformation";
import http from "../../../services/api/http";
import { SnackHTMLMessages } from "../../../components/common/utils/SnackMessages";
import AppContext from "../../../contexts/AppContext";
import UserContext from "../../../contexts/UserContext";

export const UserProfileProveVerifyForm = props => {
  const {
    defaultHideAddressPreview,
    editButtonIcon,
    editButtonLabel,
    editModeOn,
    setLoading,
    setEditModeOn,
    onSubmit,
    response,
    loading,
    isProfilePage,
    showInModal,
    submitButtonLabel,
    submitBackIcon,
    submitBackLabel
  } = props;
  const proveEligibility = get(response, 'proveEligibility', false);
  const proveVerified = get(response, 'proveVerified', false);
  const proveIdentity = get(response, 'proveIdentity', null);
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const {
    token, params: {
      mobilePhone,
      proveValidate,
      handle
    }, setUserParams
  } = useContext(UserContext);
  const [hideAddressPreview, setHideAddressPreview] = useState(defaultHideAddressPreview || false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  /**
   * App State
   */
  const userProfileForm = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      address: '',
      extended_address: '',
      city: '',
      state: '',
      zip: ''
      // , country: ''
    }
  });
  const { control, setError, setValue, handleSubmit, trigger } = userProfileForm;
  const [eligibility, setEligibility] = useState(proveEligibility);
  const [verified, setVerified] = useState(proveVerified);
  const [identity, setIdentity] = useState(proveIdentity);
  /**
   * Handlers
   */
  const renderSubmitButton = () => {
    return (
      <Button
        type="submit"
        variant="outlined"
        color="primary"
        disabled={loading === true}
        fullWidth
      >
        {submitButtonLabel}
      </Button>
    )
  }
  const toggleUpdateMode = () => setEditModeOn(state => !state);
  const triggerSetValue = (inputName, inputValue) => {
    setValue(inputName, inputValue, {
      shouldValidate: false,
      shouldDirty: false
    });
  };
  const handleUpdateAddressField = event => {
    const { target: { name, value } } = event;
    triggerSetValue(name, value);
  };
  /**
   * Request Api
   */
  const submitForm = async formData => {
    if (!formData || !token) {
      return;
    }

    setLoading(true);
    const {
      first_name,
      last_name,
      address,
      extended_address,
      city,
      state,
      zip
    } = formData;
    const response = await http(apiHost, token.accessToken, token.tokenType)
    .post('/api/web/v1/auth/update-profile', {
      username: handle,
      first_name,
      last_name,
      address,
      extended_address,
      city,
      region: state,
      postal_code: zip
    })
    .catch(() => {
    });

    if (!response || !response.data) {
      setLoading(false);

      return;
    }

    const { success, error, data } = get(response, 'data', {});
    let errorMessage = '';
    if (!success && error) {
      if (error.fieldErrors) {
        let messageList = '';
        error.fieldErrors.forEach(({ field, message }) => {
          messageList += `<li>${message}</li>`;
          setError(field === 'username' ? 'handle' : field, { type: 'checkUser', message });
        });
        errorMessage = SnackHTMLMessages(messageList);
      } else if (error.message) {
        errorMessage = error.message;
      }
    }
    await trigger().then();
    onSubmit(success, data, errorMessage);
  };
  const submitError = () => {
    setEditModeOn(true);
  };
  const evaluateEditMode = (proveEligibility, proveVerified, proveIdentity) => {
    if (
      proveEligibility === true &&
      proveVerified === false &&
      !isEmpty(proveIdentity) &&
      isProfilePage === false
    ) {
      const {
        firstName,
        lastName,
        addresses
        // ,countryCode
      } = proveIdentity;
      let proveCity = '';
      let proveState = '';
      let proveZip = '';
      let streetAddress2 = '';
      const address = addresses?.length > 0 ? addresses[0] : '';
      if (address) {
        const { city, region, postalCode, extendedAddress } = address;
        proveCity = city;
        proveState = region;
        proveZip = postalCode;
        streetAddress2 = extendedAddress || '';
      }
      setValue('first_name', firstName);
      setValue('last_name', lastName);
      setValue('address', address.address);
      setValue('extended_address', streetAddress2);
      setValue('city', proveCity);
      setValue('state', proveState);
      setValue('zip', proveZip);
      // setValue('country', countryCode);
      setHideAddressPreview(true);
      setUserParams({
        proveValidate: {
          proveEligibility: eligibility,
          proveVerified: verified,
          proveIdentity: identity
        }
      });
    } else if (isProfilePage && response?.address) {
      const address = get(response, 'address', []);
      setValue('first_name', response.firstName);
      setValue('last_name', response.lastName);
      setValue('address', address.address);
      setValue('extended_address', address.extended_address);
      setValue('city', address.city);
      setValue('state', address.region);
      setValue('zip', address.postal_code);
      // setValue('country', address.countryCode);
    }
    setEditModeOn(isEmpty(proveIdentity));
  };
  /**
   * Effects
   */
  useEffect(() => {
    evaluateEditMode(eligibility, verified, identity);
  }, [identity]);
  /* useEffect(() => {
    setDefaultHideAddressPreview(hideAddressPreview);
  }, [hideAddressPreview]); */
  useEffect(() => {
    if (
      isFirstLoad &&
      proveValidate &&
      proveValidate.proveIdentity &&
      Object.keys(proveValidate.proveIdentity).length > 0 &&
      proveValidate.proveVerified === false &&
      proveValidate.proveEligibility === true
    ) {
      setEligibility(get(proveValidate, 'proveEligibility', []));
      setVerified(get(proveValidate, 'proveVerified', []));
      setIdentity(get(proveValidate, 'proveIdentity', []));
    }
    setIsFirstLoad(false);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FormProvider {...userProfileForm} >
      <Box
        component="form"
        onSubmit={handleSubmit(submitForm, submitError)}
        autoComplete="off"
        className="pj-user-profile__form"
        direction="column"
      >
        <List
          dense
          className="pj-full-width"
          disablePadding
          hidden={editModeOn === false}
        >
          <ListItem
            disablePadding
            sx={{
              alignItems: 'center',
              justifyContent: 'start'
            }}
          >
            {
              isProfilePage !== true &&
              identity && Object.keys(identity).length &&
              <Button
                type="button"
                onClick={toggleUpdateMode}
                variant="text"
                color="primary"
                size="small"
                startIcon={submitBackIcon}
                disabled={loading === true}
                sx={{ mb: 5 }}
              >
                {submitBackLabel}
              </Button>
            }
          </ListItem>
          <ListItem disablePadding>
            <Controller
              name="first_name"
              control={control}
              rules={{ required: 'First Name is required' }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="First name"
                  className="pj-light-underline"
                  variant="standard"
                  color="primary"
                  onChange={handleUpdateAddressField}
                  inputProps={{ disabled: !editModeOn }}
                  helperText={
                    fieldState.error &&
                    (
                      <Typography
                        variant="p"
                        color="error"
                      >
                        {fieldState.error.message}
                      </Typography>
                    )
                  }
                />
              )}
            />
          </ListItem>
          <ListItem disablePadding>
            <Controller
              name="last_name"
              control={control}
              rules={{ required: 'Last Name is required' }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Last name"
                  className="pj-light-underline"
                  variant="standard"
                  color="primary"
                  onChange={handleUpdateAddressField}
                  inputProps={{ disabled: !editModeOn }}
                  helperText={
                    fieldState.error &&
                    (
                      <Typography
                        variant="p"
                        color="error"
                      >
                        {fieldState.error.message}
                      </Typography>
                    )
                  }
                />
              )}
            />
          </ListItem>
          <ListItem disablePadding>
            <AddressLookup
              toggleLookupMode={false}
              hideAddressPreview={hideAddressPreview}
              isAddressManual={false}
            />
          </ListItem>
        </List>
        <List
          dense
          className="pj-full-width"
          disablePadding
          hidden={editModeOn === true}
        >
          <ListItem
            disablePadding
            sx={{
              alignItems: 'center',
              justifyContent: 'end'
            }}
          >
            <Button
              type="button"
              variant="text"
              color="primary"
              size="small"
              disabled={loading === true}
              onClick={toggleUpdateMode}
              endIcon={editButtonIcon}
            >
              {editButtonLabel}
            </Button>
          </ListItem>
          {
            identity && Object.keys(identity).length &&
            (
              <UserProfileInformation
                params={identity}
                mobilePhone={mobilePhone}
                showInModal={showInModal}
              />
            )
          }
        </List>
        {
          isProfilePage ?
            <List>
              <ListItem>
                {renderSubmitButton()}
              </ListItem>
            </List> :
            <Container className="pj-justify-content-center">
              {renderSubmitButton()}
            </Container>
        }
      </Box>
    </FormProvider>
  );
}

UserProfileProveVerifyForm.defaultProps = {
  defaultHideAddressPreview: false,
  editButtonIcon: <img src={PICKLEJAR_DEFAULT_EDIT_ICON} alt="edit icon" />,
  editButtonLabel: 'Edit',
  editModeOn: false,
  handleUpdateAddressField: null,
  isProfilePage: false,
  loading: false,
  mobilePhone: null,
  onSubmit: null,
  setLoading: null,
  showInModal: false,
  submitBackIcon: <ArrowBack />,
  submitBackLabel: 'Back',
  submitButtonLabel: 'Continue'
}

UserProfileProveVerifyForm.propTypes = {
  defaultHideAddressPreview: PropTypes.bool,
  editButtonIcon: PropTypes.node,
  editButtonLabel: PropTypes.string,
  editModeOn: PropTypes.bool,
  handleUpdateAddressField: PropTypes.any,
  isProfilePage: PropTypes.bool,
  loading: PropTypes.bool,
  mobilePhone: PropTypes.string,
  onSubmit: PropTypes.func,
  response: PropTypes.any.isRequired,
  setEditModeOn: PropTypes.any.isRequired,
  setLoading: PropTypes.func,
  showInModal: PropTypes.bool,
  submitBackIcon: PropTypes.node,
  submitBackLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string
}
export default UserProfileProveVerifyForm;

// Packages
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Container, FormControl, FormControlLabel, FormLabel, List, ListItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';

// Realtives
import MaskedInput from '../../../components/MaskedInput';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';
import NotificationContext from '../../../contexts/NotificationContext';
import { FormatDate } from '../../../components/common/utils/FormatDate';
import http from '../../../services/api/http';
import { SnackHTMLMessages } from '../../../components/common/utils/SnackMessages';

export const UserProfileBirthDayGenderForm = props => {
  const {
    formDefaultValues,
    onSubmit,
    loading,
    setLoading,
    isProfilePage,
    showInModal
  } = props;

  const proveVerified = get(formDefaultValues, 'proveVerified', false);

  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token, params } = useContext(UserContext);
  const { notificationError } = useContext(NotificationContext);
  /**
   * App State
   */
    // const [loading, setLoading] = useState(false);
  const newDate = new Date();
  const today = FormatDate(newDate.toUTCString());
  // eslint-disable-next-line no-unused-vars
  const maxCalendarDate = FormatDate(new Date(newDate.setFullYear(newDate.getFullYear() - 10)).toUTCString());
  const [dateValue, setDateValue] = useState(today);
  const [genderValue, setGenderValue] = useState('F');
  const userProfileForm = useForm({
    defaultValues: {
      username: params.handle || '',
      gender: genderValue,
      birthday: dateValue
    },
    reValidateMode: 'onChange'
  });
  const { control, clearErrors, setError, setValue, handleSubmit, formState } = userProfileForm;
  /**
   * Handlers
   *
   */
  const handleGenderChange = event => {
    setGenderValue(event.target.value);
  };
  /**
   * Handlers
   */
  const checkUsername = async username => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v1/auth/update-profile', { username })
      .catch(() => {
      });

    if (!response || !response.data) {
      return;
    }

    const { success, error } = get(response, 'data', {});

    clearErrors('username');
    clearErrors('handle');
    if (!success && error) {
      if (error.fieldErrors) {
        error.fieldErrors.forEach(({ field, message }) =>
          setError(field, { type: 'checkUser', message })
        );
      } else if (error.message) {
        notificationError(error.message);
      }
    } else {
      clearErrors('username');
      clearErrors('handle');
    }
  };
  const checkUsernameDebounced = useRef(debounce(checkUsername, 250));
  const handleChange = async e => {
    checkUsernameDebounced.current(e.target.value);
    clearErrors('username');
    clearErrors('handle');
    setValue('username', e.target.value, { shouldValidate: true });
  };
  const handleDateChange = newValue => {
    const formattedDate = FormatDate(newValue);
    setDateValue(formattedDate);
    setValue('birthday', formattedDate);
  };
  /**
   * Api Requests
   */
  const handleSubmitForm = async formData => {
    if (!formData || !token) {
      return;
    }

    setLoading(true);
    const { username, birthday, gender } = formData;
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v1/auth/update-profile', {
        username,
        birthday,
        gender
      })
      .catch(() => {
      });

    if (!response || !response.data) {
      setLoading(false);

      return;
    }

    const { success, error, data } = get(response, 'data', {});
    let errorMessage = '';
    if (!success && error) {
      if (error.fieldErrors) {
        let messageList = '';
        error.fieldErrors.forEach(({ field, message }) => {
          messageList += `<li>${message}</li>`;
          setError(field === 'username' ? 'username' : field, { type: 'checkUser', message });
        });
        errorMessage = SnackHTMLMessages(messageList);
      } else if (error.message) {
        errorMessage = error.message;
      }
    }
    await userProfileForm.trigger().then();
    onSubmit(success, data, errorMessage);
  };
  /**
   * Effects
   */
  useEffect(() => {
    if (formDefaultValues && Object.keys(formDefaultValues).length) {
      const { gender, birthday } = formDefaultValues;
      setDateValue(birthday);
      setGenderValue(gender);
    }
  }, [formDefaultValues]);
  useEffect(() => {
    setValue('gender', genderValue);
  }, [genderValue]);
  /**
   * Renders
   *
   */
  const renderForm = () => {
    return (
      <List className="pj-full-width">
        {
          (
            (
              (
                proveVerified || showInModal
              ) && isProfilePage
            ) !== true
          ) &&
          (
            <ListItem disablePadding>
              <Controller
                name="username"
                control={control}
                rules={{
                  required: 'Handle cannot be empty',
                  validate: {
                    checkUser: () => {
                      const { handle } = formState.errors;
                      return !(
                        handle && handle.type === 'checkUser'
                      ) || handle.message?.replace('Username', 'Handle');
                    }
                  }
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      fullWidth
                      label="@Handle"
                      variant="standard"
                      className="pj-light-underline"
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: {
                          name: field.name,
                          mask: '@name',
                          valueMasked: false,
                          blocks: {
                            name: {
                              mask: /^[0-9a-zA-Z_]{1,25}$/
                            }
                          }
                        }
                      }}
                      helperText={
                        fieldState.error &&
                        (
                          <Typography
                            variant="p"
                            color="error"
                          >
                            {fieldState.error.message}
                          </Typography>
                        )
                      }
                    />
                  );
                }
                }
              />
            </ListItem>
          )
        }
        {
          proveVerified !== true &&
          (
            <>
              <ListItem disablePadding>
                <FormControl>
                  <Typography variant="small">Handle</Typography>
                  <Typography sx={{ mt: 0 }}>{params.handle}</Typography>
                </FormControl>
              </ListItem>
              <ListItem disablePadding>
                <Controller
                  name="birthday"
                  control={control}
                  rules={{
                    required: 'Birthday is required'
                  }}
                  defaultValue={dateValue}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        disableFuture
                        label="Birthday"
                        // maxDate={maxCalendarDate}
                        inputFormat="MM/DD/YYYY"
                        value={dateValue}
                        onChange={handleDateChange}
                        DialogProps={{
                          maxWidth: 'xs'
                        }}
                        renderInput={params =>
                          <TextField
                            {...field}
                            {...params}
                            fullWidth
                            label="Birthday"
                            variant="standard"
                            className="pj-light-underline"
                            helperText={
          fieldState.error &&
          (
            <Typography
              variant="p"
              color="error"
            >
              {fieldState.error.message}
            </Typography>
          )
                            }
                          />
                        }
                      />
                    </LocalizationProvider>
                  )}
                />
              </ListItem>
            </>
          )
        }
        <ListItem disablePadding>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <RadioGroup
              defaultValue="F"
              row
            >
              <FormControlLabel
                label="Female"
                control={
                  <Controller
                    name="gender"
                    control={control}
                    rules={{
                      required: 'Gender is required'
                    }}
                    render={({ field }) => (
                      <Radio
                        {...field}
                        checked={genderValue === 'F'}
                        color="secondary"
                        onChange={handleGenderChange}
                        value="F"
                      />
                    )}
                  />
                }
              />

              <FormControlLabel
                label="Male"
                control={
                  <Controller
                    name="gender"
                    control={control}
                    rules={{
                      required: 'Gender is required'
                    }}
                    render={({ field }) => (
                      <Radio
                        {...field}
                        checked={genderValue === 'M'}
                        color="secondary"
                        onChange={handleGenderChange}
                        value="M"
                      />
                    )}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
      </List>
    );
  };
  const renderButton = () => {
    return (
      <Box className="pj-d-flex pj-justify-content-center">
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          disabled={loading === true}
          fullWidth
        >
          Continue
        </Button>
      </Box>
    );
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleSubmitForm)}
      autoComplete="off"
      className="pj-user-profile__form"
    >
      {
        showInModal === true ?
          <>
            {renderForm()}
            {renderButton()}
          </> :
          <>
            <Container
              maxWidth="xs"
              className="pj-d-flex pj-justify-content-center"
            >
              {renderForm()}
            </Container>
            <Container
              maxWidth="sm"
              className="pj-d-flex pj-justify-content-center"
            >
              {renderButton()}
            </Container>
          </>
      }
    </Box>
  );
};

UserProfileBirthDayGenderForm.defaultProps = {
  disableBirthdayInput: false,
  disableHandleInput: false,
  formDefaultValues: null,
  isProfilePage: false,
  showInModal: false
};

UserProfileBirthDayGenderForm.propTypes = {
  disableBirthdayInput: PropTypes.bool,
  disableHandleInput: PropTypes.bool,
  formDefaultValues: PropTypes.object,
  isProfilePage: PropTypes.bool,
  showInModal: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
};
export default UserProfileBirthDayGenderForm;

// Packages
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, isNil, noop } from 'lodash';
import momentTz from 'moment-timezone';
import axios from 'axios';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
// import { Alert } from 'reactstrap';
//  Relatives
import AddressLookupApi from './AddressLookupApi';
import AppContext from '../../contexts/AppContext';
import http from '../../services/api/http';
import UserContext from '../../contexts/UserContext';

const AddressLookup = props => {
  const { AddressData, cache, toggleLookupMode, hideAddressPreview, disableInputs } = props;
  let cancelToken;
  const { control, setValue, getValues, trigger, reset } = useFormContext();
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const [lockReset, setLockReset] = useState(false);
  const [isAddressManual, setIsAddressManual] = useState(
    () => !isNil(cache.lat) && isNil(cache.lon) && !isEmpty(cache.lat) && !isEmpty(cache.lon) || getValues('address_manual_mode')
  );

  /**
   * Handlers
   */
  const handleChangeMode = mode => {
    setIsAddressManual(mode);
    if (!mode) {
      handleReset();
    }
  };
  const triggerNext = () => {
    trigger().then();
    if (document.getElementById('nextBtn')) {
      setTimeout(() => {
        document.getElementById('nextBtn').click();
      }, 500);
    }
  };
  const triggerSetValue = (inputName, inputValue) => {
    setValue(inputName, inputValue, {
      shouldValidate: true,
      shouldDirty: true
    });
  };
  const handleReConfirmingAddress = async addressInput => {
    const timeZoneMoment = momentTz.tz.guess();
    try {

      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      cancelToken = axios.CancelToken.source();

      const response = await http(apiHost, token.accessToken, token.tokenType)
        .get(`/api/web/geocode/default/query?q=${addressInput}`, {
          cancelToken: cancelToken.token
        })
        .catch(() => {
        });

      const { success, output } = get(response, 'data', { success: false, data: [] });
      if (!success) {
        return;
      }

      const address = get(output, '0');

      const {
        streetNumber, streetName, postalCode, stateName,
        city, occupancyIdentifier, /* countryCode, */ timezone, coordinates
      } = address;

      AddressData(address);


      // triggerSetValue('country', countryCode);
      triggerSetValue('address', `${streetNumber || ''} ${streetName || ''}`);
      triggerSetValue('zip', postalCode);
      triggerSetValue('extended_address', occupancyIdentifier);
      triggerSetValue('city', city);
      triggerSetValue('state', stateName);
      triggerSetValue('timezone', !isEmpty(timezone) ? timezone : timeZoneMoment);
      triggerSetValue('lat', coordinates.latitude);
      triggerSetValue('lon', coordinates.longitude);
      setLockReset(true);
      triggerNext();
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = fieldOnChange => address => {
    if (!address) {
      fieldOnChange('');
      handleReset();

      return;
    }

    const { fullAddress } = address;
    AddressData(address);

    fieldOnChange(fullAddress);

    handleReConfirmingAddress(fullAddress);
  };
  const getInputValues = value => !isNil(value) && !isEmpty(value) ? value : '';
  const updateFormattedAddress = () => {
    const address1 = getInputValues(getValues('address'));
    const address2 = getInputValues(getValues('extended_address'));
    const city = getInputValues(getValues('city'));
    const state = getInputValues(getValues('state'));
    const zip = getInputValues(getValues('zip'));
    // const country = getInputValues(getValues('country'));

    const formattedAddress = [address1, address2, city, state, zip /* ,country */].reduce(
      (accumulator, currentValue) => {
        let separator = '';
        if (accumulator.length) {
          separator = `${accumulator}, `;
        }
        return currentValue && currentValue.length ? separator + currentValue : accumulator;
      }, '');
    setValue('formatted_address', formattedAddress);
    trigger().then(res => console.log(res));
  };
  const handleUpdateAddressField = event => {
    const { target: { name, value } } = event;
    triggerSetValue(name, value);
    updateFormattedAddress();
    setLockReset(isEmpty(value));
  };
  const handleReset = () => {
    if (lockReset) {
      reset({
        formatted_address: '',
        address: '',
        extended_address: '',
        city: '',
        state: '',
        zip: ''
        // ,country: ''
      }, {
        keepErrors: true,
        keepDirty: true
      });
      triggerNext();
    }

  };
  /**
   * Effects
   */
  useEffect(() => {
    setValue('address_manual_mode', isAddressManual);
  }, [isAddressManual]);

  return (
    <Controller
      name="formatted_address"
      control={control}
      rules={{ required: (!hideAddressPreview && !isAddressManual) ? 'Address is required' : false }}
      render={({ field, fieldState }) => {
        const { value, onChange } = field;

        return (
          <FormControl>
            <AddressLookupApi
              onChange={handleChange(onChange)}
              onChangeMode={handleChangeMode}
              manualMode={isAddressManual}
              value={value}
              label="Add a location"
              placeholder="Find your address..."
              enableToggleMode={toggleLookupMode}
              hideAddressPreview={hideAddressPreview}
            >
              <Grid
                item
                xs={12}
              >

                <Controller
                  name="address"
                  control={control}
                  rules={{ required: 'Address is required' }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      onChange={handleUpdateAddressField}
                      label="Address"
                      className="pj-light-underline"
                      variant="standard"
                      color="primary"
                      placeholder="Street Address or P.O. Box"
                      disabled={disableInputs}
                      helperText={
                        fieldState.error && lockReset &&
                        (
                          <Typography
                            variant="p"
                            color="error"
                          >
                            {fieldState.error.message}
                          </Typography>
                        )
                      }
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Controller
                  name="extended_address"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      onChange={handleUpdateAddressField}
                      label="Address 2"
                      className="pj-light-underline"
                      variant="standard"
                      color="primary"
                      placeholder="Apt, Suite, Unit Building (optional)"
                      disabled={disableInputs}
                      helperText={
                        fieldState.error && !!isAddressManual &&
                        (
                          <Typography
                            variant="p"
                            color="error"
                          >
                            {fieldState.error.message}
                          </Typography>
                        )
                      }
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                className="pj-d-flex"
              >
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: 'City is required' }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      onChange={handleUpdateAddressField}
                      label="City"
                      className="pj-light-underline"
                      variant="standard"
                      color="primary"
                      disabled={disableInputs}
                      helperText={
                        fieldState.error && lockReset &&
                        (
                          <Typography
                            variant="p"
                            color="error"
                          >
                            {fieldState.error.message}
                          </Typography>
                        )
                      }
                    />
                  )}
                />
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: 'State is required' }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      onChange={handleUpdateAddressField}
                      label="State"
                      className="pj-light-underline"
                      variant="standard"
                      color="primary"
                      disabled={disableInputs}
                      helperText={
                        fieldState.error && lockReset &&
                        (
                          <Typography
                            variant="p"
                            color="error"
                          >
                            {fieldState.error.message}
                          </Typography>
                        )
                      }
                    />
                  )}
                />
                <Controller
                  name="zip"
                  control={control}
                  rules={{ required: 'Zip is required' }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      onChange={handleUpdateAddressField}
                      label="Zip"
                      className="pj-light-underline"
                      variant="standard"
                      color="primary"
                      disabled={disableInputs}
                      helperText={
                        fieldState.error && lockReset &&
                        (
                          <Typography
                            variant="p"
                            color="error"
                          >
                            {fieldState.error.message}
                          </Typography>
                        )
                      }
                    />
                  )}
                />

                {/* <Controller
                  name="country"
                  control={control}
                  rules={{ required: 'Country is required' }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      onChange={handleUpdateAddressField}
                      label="Country"
                      className="pj-light-underline"
                      variant="standard"
                      color="primary"
                      disabled={disableInputs}
                      helperText={
                        fieldState.error && lockReset &&
                        (
                          <Typography
                            variant="p"
                            color="error"
                          >
                            {fieldState.error.message}
                          </Typography>
                        )
                      }
                    />
                  )}
                /> */}
              </Grid>
            </AddressLookupApi>
            <FormHelperText>
              {fieldState.error && (
                <Typography
                  variant="p"
                  color="error"
                >
                  {fieldState.error.message}
                </Typography>
              )}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

AddressLookup.defaultProps = {
  AddressData: noop,
  hideAddressPreview: false,
  disableInputs: false,
  cache: {},
  toggleLookupMode: true
};

AddressLookup.propTypes = {
  AddressData: PropTypes.func,
  hideAddressPreview: PropTypes.bool,
  disableInputs: PropTypes.bool,
  cache: PropTypes.object,
  toggleLookupMode: PropTypes.bool
};

export default AddressLookup;

import React from 'react';
import PropTypes from 'prop-types';
import { LayoutProvider } from '../contexts/LayoutContext/LayoutProvider';
import Login from './Login';
import { PICKLEJAR_DEFAULT_BACKGROUND_IMAGE, PICKLEJAR_DEFAULT_LOGO_IMAGE } from "../const/ClaimsTheme";

export const PicklejarLogin = props => {
  const {
    backgroundImage,
    logoImage,
    loginRedirect
  } = props;
  return (
    <LayoutProvider
      backgroundImage={backgroundImage}
      logoImage={logoImage}
      loginRedirect={loginRedirect}
    >
      <Login loginRedirect={loginRedirect}/>
    </LayoutProvider>
  );
};

PicklejarLogin.defaultProps = {
  backgroundImage: PICKLEJAR_DEFAULT_BACKGROUND_IMAGE,
  logoImage: PICKLEJAR_DEFAULT_LOGO_IMAGE
};

PicklejarLogin.propTypes = {
  backgroundImage: PropTypes.string,
  logoImage: PropTypes.string,
  loginRedirect: PropTypes.string.isRequired
};

export default PicklejarLogin;

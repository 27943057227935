// Packages
import React, { useId, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { Avatar, Badge, Box, CircularProgress, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';

// Relatives
import ImageModalCrop from './ImageModalCrop';
// Style
import 'react-image-crop/dist/ReactCrop.css';
import { PICKLEJAR_DEFAULT_AVATAR_IMAGE, PICKLEJAR_DEFAULT_EDIT_ICON, picklejarLightBackground } from '../../const/ClaimsTheme';

const ImageCropInput = props => {
  const { onCancel, onUpload, aspect, name, showPreview, previewUrl, loading, canEdit } = props;
  const [src, setSrc] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect });
  const [openCrop, setOpenCrop] = useState(false);
  const id = useId();

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSrc(reader.result);
        setOpenCrop(true);
      });
      reader.readAsDataURL(e.target.files[0]);
      setOriginalFile(e.target.files[0]);
    }
  };

  const resetState = () => {
    setOpenCrop(false);
    setSrc(null);
    setCrop({ unit: '%', width: 30, aspect });
    setOriginalFile(null);
  };

  const handleClickCancel = () => {
    setOpenCrop(false);
    resetState();
    onCancel();
  };

  const handleClickSubmit = async croppedImageUrl => {
    const blob = await fetch(croppedImageUrl).then(r => r.blob());
    onUpload(name, {
      originalSrc: src,
      originalFile,
      croppedImageUrl,
      file: new File([blob], originalFile.name, { ...pick(originalFile, ['type']) })
    });
    resetState(true);
  };

  return (
    <Box className="image-crop-input">
      <Box className="image-crop-input__input">
        <TextField
          id={id}
          name={name}
          type="file"
          label=""
          sx={{ backgroundColor: picklejarLightBackground }}
          inputProps={{
            multiple: true,
            accept: 'image/*'
          }}
          hidden
          disabled={loading}
          onChange={onSelectFile}
        />
        {
          showPreview &&
          (
            <Stack
              direction="row"
              spacing={2}
            >
              <Badge
                badgeContent={
                  canEdit === true && (
                    <Box
                      component="label"
                      htmlFor={id}
                      sx={{
                        width: '36px',
                        height: '36px',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                      }}
                    >
                      {
                        loading === true ?
                          <CircularProgress
                            color="primary"
                            size={20}
                          /> :
                          <img src={PICKLEJAR_DEFAULT_EDIT_ICON} alt="edit icon" />
                      }
                    </Box>
                  )
                }
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                sx={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  '.MuiBadge-badge': {
                    backgroundColor: 'transparent',
                    width: 'auto',
                    padding: 0
                  }
                }}
              >
                <Avatar
                  alt="profile"
                  src={previewUrl}
                  sx={{ width: '120px', height: '120px', border: 'none' }}
                />
              </Badge>
            </Stack>
          )
        }
      </Box>
      <ImageModalCrop
        className="image-input__modal"
        src={src}
        fileName={get(originalFile, 'fileName', '')}
        openModal={openCrop}
        onClose={handleClickCancel}
        onSubmit={handleClickSubmit}
        crop={crop}
        setCrop={setCrop}
      />
    </Box>
  );
};

ImageCropInput.defaultProps = {
  name: '',
  aspect: 16 / 9,
  onUpload: noop,
  onCancel: noop,
  showPreview: true,
  previewUrl: PICKLEJAR_DEFAULT_AVATAR_IMAGE,
  loading: false,
  canEdit: true
};

ImageCropInput.propTypes = {
  name: PropTypes.string,
  aspect: PropTypes.number,
  onUpload: PropTypes.func,
  onCancel: PropTypes.func,
  showPreview: PropTypes.bool,
  previewUrl: PropTypes.string,
  loading: PropTypes.bool,
  canEdit: PropTypes.bool
};

export default ImageCropInput;

import { createContext } from 'react';

const NotificationContext = createContext({
  notification: null,
  notificationText: null,
  notificationSuccess: () => {
    return null;
  },
  notificationError: () => {
    return null;
  },
  notificationClear: () => {
    return null;
  }
});

export default NotificationContext;

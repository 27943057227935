import React from 'react';
import { Box } from '@mui/material';
import { PICKLEJAR_DEFAULT_BACKGROUND_IMAGE } from '../../../const/ClaimsTheme';

export const ClaimsSidebar = () => {
  return (
    <Box
      className="ClaimsSidebar"
      alignItems="flex-start"
      sx={{
        backgroundImage: `url(${PICKLEJAR_DEFAULT_BACKGROUND_IMAGE})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom'
      }}
    >
      <img
        src="/images/logos/picklejar.svg"
        alt="logo"
      />
    </Box>
  );
};

// Packages
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Relatives
import LayoutContext from './LayoutContext';
import { PICKLEJAR_DEFAULT_BACKGROUND_IMAGE, PICKLEJAR_DEFAULT_LOGO_IMAGE } from '../../const/ClaimsTheme';
import { PICKLEJAR_DEFAULT_PROFILE_PAGE_URL } from "../../const/PicklejarSettings";


export const LayoutProvider = props => {
  const { children, backgroundImage, logoImage } = props;
  /**
   * App State
   */
  const [wrapperClassName, setWrapperClassName] = useState(null);
  const [picklejarBackgroundImage, setPicklejarBackgroundImage] =
    useState(backgroundImage || PICKLEJAR_DEFAULT_BACKGROUND_IMAGE);
  const [header, setHeader] = useState(null);
  const [pageClass, setPageClass] = useState(null);
  const [showHeader, setShowHeader] = useState(null);
  const [hideLogo, setHideLogo] = useState(false);
  const [picklejarLogo, setPicklejarLogo] = useState(logoImage || PICKLEJAR_DEFAULT_LOGO_IMAGE);
  const [subtitle, setSubtitle] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const LoginValueMemo = useMemo(
    () => (
      {
        wrapperClassName,
        picklejarBackgroundImage,
        header,
        pageClass,
        showHeader,
        picklejarLogo,
        hideLogo,
        subtitle,
        pageTitle,
        setPicklejarBackgroundImage,
        setWrapperClassName,
        setHeader,
        setHideLogo,
        setPicklejarLogo,
        setPageClass,
        setShowHeader,
        setSubtitle,
        setPageTitle
      }
    ),
    [
      wrapperClassName,
      picklejarBackgroundImage,
      header,
      pageClass,
      showHeader,
      picklejarLogo,
      subtitle,
      pageTitle,
      setWrapperClassName,
      setPicklejarBackgroundImage,
      setHeader,
      setHideLogo,
      setPicklejarLogo,
      setPageClass,
      setShowHeader,
      setSubtitle,
      setPageTitle
    ]
  );
  return (
    <LayoutContext.Provider value={LoginValueMemo}>{children}</LayoutContext.Provider>
  );
};

LayoutProvider.defaultProps = {
  children: null,
  backgroundImage: PICKLEJAR_DEFAULT_BACKGROUND_IMAGE,
  logoImage: PICKLEJAR_DEFAULT_LOGO_IMAGE,
  profilePage: PICKLEJAR_DEFAULT_PROFILE_PAGE_URL
};

LayoutProvider.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  logoImage: PropTypes.string,
  profilePage: PropTypes.string
};

import PropTypes from 'prop-types';

export const AttachmentModel = {
  id: PropTypes.number,
  ticket_head_id: PropTypes.number,
  ticket_body_id: PropTypes.number,
  media_id: PropTypes.number,
  created_at: PropTypes.number,
  created_by: PropTypes.number,
  _related: PropTypes.array,
  _misc: {
    media: {
      title: PropTypes.string,
      alt: PropTypes.string,
      type: PropTypes.string,
      tags: [],
      description: PropTypes.string,
      duration: PropTypes.any,
      url: PropTypes.string,
      original_image: PropTypes.string,
      small_image: PropTypes.string,
      medium_image: PropTypes.string,
      large_image: PropTypes.string,
      video_image: {
        id: PropTypes.number,
        originalImage: PropTypes.string,
        smallImage: PropTypes.string,
        mediumImage: PropTypes.string,
        largeImage: PropTypes.string
      }
    }
  },
  _links: []
};

export default AttachmentModel;

// Packages
import React from 'react';
import { ClaimsBody } from '../../templates/claims/claims-body/ClaimsBody';
import ClaimsLayout from '../../layouts/Claims';
// Relatives

const ClaimsIndex = () => {
  return (
    <ClaimsLayout title="Claims" breadcrumb={['']}>
      <ClaimsBody/>
    </ClaimsLayout>
  );
};

export default ClaimsIndex;

import PropTypes from 'prop-types';
import { MediaModel } from './MediaModel';

export const SocialLinksModel = {
  id: PropTypes.number,
  system_name: PropTypes.string,
  name: PropTypes.string,
  base_url: PropTypes.string,
  media_id: PropTypes.number,
  display_order: PropTypes.string,
  status: PropTypes.number,
  created_at: PropTypes.number,
  updated_at: PropTypes.number,
  created_by: PropTypes.number,
  updated_by: PropTypes.number,
  _related: PropTypes.array,
  _misc: {
    icon: MediaModel
  },
  _links: PropTypes.array
};

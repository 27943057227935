import { EncryptStorage } from 'encrypt-storage';

const encryptStorage = new EncryptStorage('26175ff3ef0061a6cc355f06fac1fe48', {
  prefix: '@web-picklejar-state',
  encAlgorithm: 'Rabbit'
});

export const loadState = sessionKey => {
  const serializedState = encryptStorage.getItem(sessionKey);
    if (serializedState === null) {
      return {};
    }
  
    return serializedState;
};

export const saveState = (sessionKey, state) => {
  try {
    const serializedState = JSON.stringify(state);
    encryptStorage.setItem(sessionKey, serializedState);
  } catch {
    // ignore write errors
  }
};

export const clearState = sessionKey => {
  try {
    encryptStorage.removeItem(sessionKey);
  } catch {
    // ignore write errors
  }
};

// Packages
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';

// Relatives
import UserContext from '../../contexts/UserContext';
import ClaimsHeader from './ClaimsHeader/ClaimsHeader';
import { ClaimsSidebar } from './ClaimsSidebar/ClaimsSidebar';
// import LayoutContext from '../../contexts/LayoutContext';

const ClaimsLayout = props => {
  const { children, noPadding, className, pageClassName, showHeader, title, breadcrumb, specialLayout } = props;
  const { isLogged } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState(() => get(location, 'state.alerts', []));
  // const { path, flash, breadcrumb, title } = useContext(LayoutContext);

  const handleClickRemoveAlert = position => () => {
    setAlerts(state => {
      const newState = [...state];
      newState.splice(position, 1);

      return newState;
    });
  };

  useEffect(() => {
    navigate('.', { state: { ...location.state, alerts } });
  }, [alerts]);

  return (
    <Box className={classNames('Claims pj-layout-wrapper', { [className]: className })}>
      <Helmet>
        <title>PickleJar - Claims Portal - {title}</title>
      </Helmet>

      <Grid
        container
        spacing={0}
        className={classNames('pj-h-100', pageClassName)}
      >
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <ClaimsSidebar/>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          lg={9}
        >
          {
            showHeader &&
            <ClaimsHeader
              hideHandlerOnMobile
              specialHeader={specialLayout}
              isLogged={isLogged}
              title={title}
            />
          }
          <Box className="pj-body-wrapper">
            <Container maxWidth="lg" sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              {breadcrumb && breadcrumb.length > 0 && (
                <Box className="wrapper__breadcrumb">
                  {breadcrumb.map((item, i) => (
                    <small
                      key={i}
                      className="breadcrumb__item"
                    >
                      {item}
                    </small>
                  ))}
                </Box>
              )}
              <Box className="pj-content-wrapper">
                {alerts.length > 0 && (
                  <div className="layout-alerts container">
                    {alerts.map((alert, i) => (
                      <div
                        key={i}
                        className="layout-alert"
                      >
                        <div className="alert__message">{alert}</div>
                        <div
                          className="alert__close"
                          onClick={handleClickRemoveAlert(i)}
                        >
                          x
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <Box className={classNames('page ', { 'p-0': noPadding, [className]: className })}>{children}</Box>
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ClaimsLayout.defaultProps = {
  className: '',
  pageClassName: '',
  title: '',
  children: null,
  noPadding: false,
  showHeader: true,
  specialLayout: false,
  breadcrumb: []
};

ClaimsLayout.propTypes = {
  className: PropTypes.string,
  pageClassName: PropTypes.string,
  title: PropTypes.string || PropTypes.node,
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  showHeader: PropTypes.bool,
  specialLayout: PropTypes.bool,
  breadcrumb: PropTypes.array
};

export default ClaimsLayout;

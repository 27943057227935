import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import axios from 'axios';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import omit from 'lodash/omit';
import classNames from 'classnames';
import { Autocomplete, Box, CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ArrowDropDown, LocationOn, Close } from '@mui/icons-material';

// Relatives
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import http from '../../services/api/http';

const AddressLookupApi = props => {
  const {
    manualMode: manualModeProp,
    placeholder,
    label,
    onChange,
    onChangeMode,
    value,
    children,
    enableToggleMode, /* , required */
    hideAddressPreview
  } = props;
  /**
   *
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  /**
   * App State
   */
  let cancelToken;
  const [manualMode, setManualMode] = useState(manualModeProp);
  const [loading, setLoading] = useState(false);
  const [valueAutocomplete, setValueAutocomplete] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  /**
   * Handlers
   */
  const handleClick = () => {
    setManualMode(!manualMode);
    onChangeMode(!manualMode);
  };
  const handleChange = selectedOption => {
    if (!selectedOption) {
      onChange(null);

      return;
    }

    const { value, optionals } = selectedOption;
    if (optionals) {
      onChange({ fullAddress: value, ...optionals });

      return;
    }

    onChange({ fullAddress: value });
  };
  const updateAutocompleteValueOnChange = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValueAutocomplete(newValue);
    handleChange(newValue);
  };
  const updateAutocompleteValueOnInputChange = (event, newInputValue) => setInputValue(newInputValue);
  const handleClearAutocomplete = () => {
    updateAutocompleteValueOnInputChange('');
    updateAutocompleteValueOnChange('');
  }
  /**
   * Api Request
   */
  const promiseOptions = inputValue => new Promise(async resolve => {
    if (!inputValue) {
      resolve([]);

      return;
    }

    try {
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
      cancelToken = axios.CancelToken.source();
      setLoading(true);
      const response = await http(apiHost, token.accessToken, token.tokenType)
        .get(`/api/web/geocode/default/autocomplete?q=${inputValue}&lookup=0`, {
          cancelToken: cancelToken.token
        })
        .catch(() => {
        });

      setLoading(false);
      const { success, output } = get(response, 'data', { success: false, data: [] });
      if (!success) {
        resolve([]);

        return;
      }

      const result = output.reduce(
        (acum, address) => [
          ...acum,
          {
            value: get(address, 'description'),
            label: get(address, 'description'),
            optionals: omit(address, ['description'])
          }
        ],
        []
      );
      resolve(result);
    } catch (error) {
      console.log(error);
    }
  });
  /**
   * Effects
   */
  useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(valueAutocomplete ? [valueAutocomplete] : []);
      return undefined;
    }

    promiseOptions(inputValue).then(results => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [valueAutocomplete];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };

  }, [valueAutocomplete, inputValue]);

  return (
    <Box className={classNames('address-lookup', { 'lookup--model-manual': manualMode })}>
      {
        !manualMode &&
        (
            <Autocomplete
              hidden={hideAddressPreview}
              id="autocomplete-address"
              getOptionLabel={option => option.value}
              loading={loading}
              filterOptions={x => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={valueAutocomplete}
              noOptionsText="No locations"
              onChange={updateAutocompleteValueOnChange}
              onInputChange={updateAutocompleteValueOnInputChange}
              renderInput={params => (
                <TextField
                  {...params}
                  color="primary"
                  variant="standard"
                  label={label}
                  placeholder={placeholder}
                  fullWidth
                  focused
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{position: 'absolute', right: 0, color: 'white'}}
                      >
                        {
                          loading ?
                            <CircularProgress
                              color="primary"
                              size={20}
                            /> :
                            <>
                              <Button
                                type="clear"
                                startIcon={<Close />}
                                onClick={handleClearAutocomplete}
                                hidden={isEmpty(valueAutocomplete)}
                                size="small"
                                sx={{minWidth: 'unset'}}
                                color="inherit"
                                variant="text"
                              />
                              <ArrowDropDown color="primary"/>
                            </>
                        }
                      </InputAdornment>
                    )
                  }}
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid
                      container
                      alignItems="center"
                    >
                      <Grid
                        item
                        sx={{ display: 'flex', width: 44 }}
                      >
                        <LocationOn sx={{ color: 'text.secondary' }}/>
                      </Grid>
                      <Grid
                        item
                        sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >
                          {option.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
        )}

      {
        enableToggleMode &&
        (
          <button
            id="manualModeBtn"
            type="button"
            className="btn btn-primary"
            onClick={handleClick}
            disabled={loading}
          >
            {manualMode ? 'Lookup Mode' : 'Manual Mode'}
          </button>
        )
      }
      {(
         manualMode || !enableToggleMode
       ) && <div className="address-lookup__manual-mode">{children}</div>}
    </Box>
  );
};

AddressLookupApi.defaultProps = {
  children: null,
  value: '',
  required: 'streetNumber,streetName,postalCode,city,stateName,stateCode,country,countryCode',
  manualMode: false,
  placeholder: '',
  label: '',
  onChange: noop,
  onChangeMode: noop,
  enableToggleMode: true,
  hideAddressPreview: false
};

AddressLookupApi.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  required: PropTypes.string,
  manualMode: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onChangeMode: PropTypes.func,
  enableToggleMode: PropTypes.bool,
  hideAddressPreview: PropTypes.bool
};

export default AddressLookupApi;

/* eslint-disable camelcase */
// Packages
import * as React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, ListItemAvatar } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Relative
import { ArtistModel } from '../../models/ArtistModel';
import { PICKLEJAR_DEFAULT_AVATAR_IMAGE, PICKLEJAR_EVENTS_DEFAULT_IMAGE } from '../../../../const/ClaimsTheme';


const HandleCards = props => {
  const { data: { avatar, banner, handle, id, stageName } } = props;

  return (
    <Card
      sx={{ borderRadius: '14px' }}
      className="claim-card"
      key={`claims-details-claims-card-${id}`}
    >
      <CardContent
        sx={
          {
            alignItems: 'space-between',
            flexDirection: 'column'
          }
        }
      >
        <img
          src={banner.mediumImage || PICKLEJAR_EVENTS_DEFAULT_IMAGE}
          alt="claim background"
          className="background"
        />
        <div className="overlay"/>

        <ListItemAvatar
          className="avatar-holder justify-content-start"
          sx={{ mt: 4, me: 3 }}
        >
          <Avatar
            alt={stageName}
            src={avatar.mediumImage || PICKLEJAR_DEFAULT_AVATAR_IMAGE}
            className="avatar-image"
          />
          <Box
            className="avatar-name"
            sx={{ mx: 2 }}
          >
            <strong className="pj-text-primary text-ellipsis">
              {handle || 'Anonymous'}
            </strong>
          </Box>
        </ListItemAvatar>
      </CardContent>
    </Card>
  );
};

HandleCards.propTypes = {
  data: PropTypes.shape(ArtistModel).isRequired
};

export default HandleCards;

/* eslint-disable camelcase */
// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { ProveVerifyModel } from '../Claims/models/ProveVerifyModel';

// Relatives

const UserProfileInformation = props => {
  const { params, mobilePhone, showInModal } = props;
  const {
    firstName,
    lastName,
    addresses,
    emailAddresses
  } = params;
  const address = addresses?.length ? addresses[0] : null;
  /**
   * App Contexts & Params
   */
  return (
    <List dense>
      <ListItem disablePadding>
        <ListItemText
          primary={<Typography variant="labels">Full Name</Typography>}
          secondary={<Typography
            variant={showInModal ? 'body1' : 'body2'}
            component="h5"
          >{firstName} {lastName}</Typography>}
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={<Typography variant="labels">Phone Number</Typography>}
          secondary={<Typography
            variant={showInModal ? 'body1' : 'body2'}
            component="h5"
          >{mobilePhone ?? '-'}</Typography>}
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={<Typography variant="labels">Address</Typography>}
          secondary={
            <Typography
              variant={showInModal ? 'body1' : 'body2'}
              component="h5"
            >
              { address?.address ? address.address : '-' }
              {!isEmpty(address?.extended_address) ? `, ${address.extended_address}` : ''}
              {!isEmpty(address?.city) ? `, ${address.city}` : ''}
              {!isEmpty(address?.region) ? `, ${address.region}` : ''}
              {!isEmpty(address?.postalCode) ? `, ${address.postalCode}` : ''}
            </Typography>
          }
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={<Typography variant="labels">Email</Typography>}
          secondary={<Typography
            variant={showInModal ? 'body1' : 'body2'}
            component="h5"
          >{emailAddresses?.length && !isEmpty(emailAddresses[0])? emailAddresses[0] : '-'}</Typography>}
        />
      </ListItem>
    </List>
  );
};

UserProfileInformation.defaultProps = {
  showInModal: false
};

UserProfileInformation.propTypes = {
  params: PropTypes.shape(ProveVerifyModel).isRequired,
  mobilePhone: PropTypes.string.isRequired,
  showInModal: PropTypes.bool
};

export default UserProfileInformation;

import PropTypes from 'prop-types';
import { MediaModel } from './MediaModel';

export const ClaimsExtraInformation = {
  entity_id: PropTypes.number,
  handle: PropTypes.string,
  stage_name: PropTypes.string,
  _related: PropTypes.array,
  _misc: PropTypes.shape({
    avatar: PropTypes.shape(MediaModel),
    banner: PropTypes.shape(MediaModel)
  })
};

export default ClaimsExtraInformation;

// Packages
import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Menu, MenuItem } from '@mui/material';
import { AccountCircle, Logout } from '@mui/icons-material';

// Relatives
import UserContext from '../../../contexts/UserContext';
import { picklejarBorderColor } from '../../../const/ClaimsTheme';
import { PICKLEJAR_DEFAULT_PROFILE_PAGE_URL } from "../../../const/PicklejarSettings";


const ClaimsMenu = props => {
  const { onClose, isMobile, parentRef, isOpen } = props;
  const { logout, params } = useContext(UserContext);
  const navigate = useNavigate();
  const navigateToProfilePage = () => {
    navigate(PICKLEJAR_DEFAULT_PROFILE_PAGE_URL)
  }
  const MobileMenu = [
    <MenuItem
      onClick={navigateToProfilePage}
      key="6"
      sx={{ padding: '16px 25px', minWidth: '280px' }}
    >
      <AccountCircle />
      <span>Profile</span>
    </MenuItem>,
    <MenuItem
      onClick={logout}
      key="7"
      sx={{ padding: '16px 25px', minWidth: '280px' }}
    >
      <Logout />
      <span>Logout</span>
    </MenuItem>
  ];

  return (
    <Menu
      sx={{ maxWidth: '300px' }}
      anchorEl={parentRef.current}
      id="primary-search-account-menu-mobile"
      keepMounted
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <li
        className="menu-header pj-text-center"
        style={{ borderBottom: `solid thin ${picklejarBorderColor}`, padding: '8px' }}
      >
        <strong>{isMobile ? params.handle : 'User Options'}</strong>
      </li>
      {MobileMenu}
    </Menu>
  );
};

ClaimsMenu.defaultProps = {
  parentRef: null,
  ngos: [],
  ngoSelected: null,
  isMobile: false,
  isOpen: false,
  onOpen: noop,
  onClose: noop,
  specialMenu: false,
  onSelectNGO: noop
};

ClaimsMenu.propTypes = {
  parentRef: PropTypes.object,
  ngos: PropTypes.array,
  ngoSelected: PropTypes.object,
  isMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  specialMenu: PropTypes.bool,
  onSelectNGO: PropTypes.func
};

export default ClaimsMenu;

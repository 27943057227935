// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Relatives
import LayoutContext from '../../../../contexts/LayoutContext';
import UserProfileImageForm from "../../../ProfileDetails/components/UserProfileImageForm";
import UserProfileGenresForm from "../../../ProfileDetails/components/UserProfileGenresForm";

const UserProfileStep3 = props => {
  const { onSubmit, setLoading, loading, response } = props;
  /**
   * App Contexts & Params
   */
  const {
    setHeader,
    setSubtitle,
    setPageTitle,
    setPageClass,
    setHideLogo
  } = useContext(LayoutContext);
  /**
   * App State
   */
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  /**
   * Handlers
   */
  const handleSubmit = (success, data, message) => {
    onSubmit(success, data, message);
  }
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setPageTitle('Update Profile');
    setHideLogo(true);
    setHeader(() =>
      <Typography
        color="primary"
        variant="h1"
        className="pj-login-header"
      >Tell us about you!</Typography>
    );
    setSubtitle(null);
    setPageClass('UserProfile');
  }, []);

  return (
    <Grid
      container
      className="pj-user-profile__form"
      maxWidth="md"
    >
      <Grid
        item
        xs={12}
      >
        <UserProfileImageForm
          onSubmit={null}
          loadingAvatar={loadingAvatar}
          setLoadingAvatar={setLoadingAvatar}
          canEdit
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography variant="body2">Tell us what music you like</Typography>
        <UserProfileGenresForm
          customErrorMessage="Please select a genre to continue or skip"
          loading={loading || loadingAvatar}
          onSubmit={handleSubmit}
          setLoading={setLoading}
          submitButtonLabel="Continue"
          response={response}
        />
      </Grid>
    </Grid>
  );
};

UserProfileStep3.defaultProps = {};

UserProfileStep3.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  response: PropTypes.any.isRequired
};

export default UserProfileStep3;

/* eslint-disable camelcase */
import * as React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Avatar, Box, List, ListItem, ListItemAvatar, Typography } from '@mui/material';
import AttachmentList from '../../../../components/common/attachments';
import { PICKLEJAR_DEFAULT_AVATAR_IMAGE, picklejarBorderInputColor } from '../../../../const/ClaimsTheme';

const ClaimsMessages = props => {
  const {
    content:
      {
        name_user,
        text,
        created_at,
        _misc,
        _related
      }, isSameUser, isSupport
  } = props;
  const date = moment.unix(created_at).format('DD MMM yyyy - hh:mm');
  const attachmentsList = get(_misc, 'attachments', {});
  const avatar = get(_related, 'created_by._misc.avatar.original_image');
  const showAvatar = avatar => (
    <ListItemAvatar sx={{ minWidth: '40px' }}>
      <Avatar
        alt={name_user}
        src={avatar || PICKLEJAR_DEFAULT_AVATAR_IMAGE}
        className="avatar-image"
        hidden={isSameUser}
      />
    </ListItemAvatar>
  );
  const getItem = () => {
    return <ListItem
      alignItems="flex-start"
      sx={{ paddingTop: 0, paddingBottom: 0 }}
    >
      {
        isSupport === true && showAvatar(avatar)
      }
      <Box
        className={isSupport ? 'supportMessage primary' : 'supportMessage secondary'}
        component="div"
      >
        <Typography
          variant="h6"
          hidden={isSameUser}
          sx={{ 'borderBottom': `solid thin ${picklejarBorderInputColor}`, pb: 1, mb: 1, fontSize: '1rem' }}
        >{name_user}</Typography>
        <Box
          component="div"
          variant="supportBody"
          color={isSupport ? 'support' : 'user'}
        >
          <>
            <Typography fontSize=".9rem">{text}</Typography>
            {
              attachmentsList.length > 0 &&
              (
                <AttachmentList
                  customClassName="pj-ticket-content-attachment"
                  attachmentList={attachmentsList}
                  defaultState={false}
                  uploadFiles={false}
                />
              )
            }
            <Typography
              component="small"
              variant="supportBody"
              color={isSupport ? 'support' : 'user'}
              className="message-date"
            >
              {date}
            </Typography>
          </>
        </Box>
      </Box>

      {
        isSupport === false && showAvatar(avatar)
      }
    </ListItem>;
  };

  return (
    isSameUser ? <List
      disablePadding
      component="ul"
    >{getItem()}</List> : getItem()
  );
};

ClaimsMessages.defaultProps = {
  user: 'Admin',
  content: null,
  isSameUser: false,
  isSupport: false
};

ClaimsMessages.propTypes = {
  user: PropTypes.any,
  content: PropTypes.any,
  isSameUser: PropTypes.bool,
  isSupport: PropTypes.bool
};


export default ClaimsMessages;

/* eslint-disable camelcase */
/* eslint-disable operator-linebreak */
// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { get } from 'lodash';
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { Attachment, Send } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

// Relatives
import ClaimsLayout from '../../layouts/Claims/ClaimsLayout';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import {
  picklejarAvatarSizeLg,
  picklejarBorderInputColor,
  picklejarBorderRadius,
  picklejarBoxShadow,
  picklejarLightBackground,
  picklejarTextColorGray
} from '../../const/ClaimsTheme';
import ClaimsMessages from './components/ClaimsMessages';
import ClaimsChips from './components/ClaimsChips';
import { UserModel } from './models/UserModel';
import { DepartmentModel } from './models/DepartmentModel';
import { TicketModel } from './models/TicketModel';
import { AttachmentModel } from './models/AttachmentModel';
import { ClaimsExtraInformation } from './models/ClaimsExtraInformation';
import { CreateAttachmentData, RemoveAttachmentData } from '../../components/common/attachments/AttachmentData';
import AttachmentList from '../../components/common/attachments/AttachmentList';
import { SocialLinksModel } from './models/SocialLinksModel';
import NotificationContext from '../../contexts/NotificationContext';

// eslint-disable-next-line
const ClaimsDetails = props => {
  /**
   * App Contexts & Params
   */
  const user = useContext(UserContext);
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { notificationSuccess, notificationError } = useContext(NotificationContext);
  /**
   * App State
   */
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [reloadClaimsContent, setReloadClaimsContent] = useState(true);
  const [uploadFiles, setUploadFiles] = useState(false);
  /**
   * Ticket State
   */
  const [ticket, setTicket] = useState(null);
  const [ticketId, setTicketId] = useState(null);
  const [ticketContent, setTicketContent] = useState([]);
  const [ticketHeaderAttachment, setTicketHeaderAttachment] = useState([]);
  const status = get(ticket, 'status', 'open');
  /**
   * Claims State
   */
  const [handleClaim, setHandleClaim] = useState('');
  const [handleTypeInformation, setHandleTypeInformation] = useState(null);
  const [handleBody, setHandleBody] = useState({});
  const [socialNetworksLinks, setSocialNetworksLinks] = useState({});
  /**
   * Form State
   */
  const [formAttachments, setFormAttachments] = useState([]);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [successfullyMessageSubmitted, setSuccessfullyMessageSubmitted] = useState({
    success: null,
    output: null,
    message: null
  });
  const chatMessageForm = useForm({
    defaultValues: {
      text: '',
      attachment: []
    }
  });
  const { control, handleSubmit, reset } = chatMessageForm;
  /**
   *  Handlers
   */
  const onChange = event => {
    setFormAttachments([...formAttachments, ...CreateAttachmentData([...event.target.files])]);
  };
  const removeAttachment = index => {
    setFormAttachments(RemoveAttachmentData(formAttachments, index));
  };
  const resetFormState = (message = null, type = 'error') => {
    if (message) {
      switch (type) {
        case 'error':
          notificationError(message);
          break;
        case 'success':
          notificationSuccess(message);
          break;
        default:
          notificationError(message);
      }
    }
    setFormAttachments([]);
    resetLoadingState();
  };
  const resetLoadingState = () => {
    setLoadingSubmit(false);
    setSuccessfullyMessageSubmitted(null);
    setUploadFiles(false);
    reset();
  };
  const submitChatAttachments = async () => {
    if (!token) {
      return;
    }
    setLoadingSubmit(true);
    setUploadFiles(true);
  };
  const uploadAttachmentCompleted = (totalAttachments, totalSuccess) => {
    setLoadingSubmit(false);
    if (formAttachments.length === totalAttachments.length) {
      if (formAttachments.length === totalSuccess.length) {
        setUploadedAttachments(totalAttachments);
        setUploadFiles(false);
      } else {
        notificationError('There was an error with some attachments. Please try again');
      }
    }
  };
  const renderSocialLinks = () => {
    if (socialNetworksLinks.length > 0) {
      return (
        <Box
          sx={{
            minWidth: '100%',
            paddingLeft: { xs: 0, md: '64px' }
          }}
        >
          <Divider />
          <Typography
            component="h6"
            sx={{ mb: 0 }}
          >Social Networks:</Typography>
          {socialNetworksLinks.map(socialItem => {
            const { id, link, _related: { social_link: { system_name, name } } } = socialItem;
            return (
              <Link
                key={id}
                href={link}
                target="_blank"
                alt={name}
                sx={{
                  width: '24px',
                  height: '24px',
                  margin: '8px 16px 16px 0',
                  display: 'inline-block'
                }}
              >
                <img
                  src={`/images/icons/socialnetworks/${system_name}.svg`}
                  alt={name}
                />
              </Link>
            );
          })
          }
        </Box>
      );
    }

    return <></>;
  };
  /**
   * Api Requests
   */
  const loadClaim = async () => {
    setReloadClaimsContent(false);
    setLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType)
    .get(`/api/web/v2/ownership-change-request/${id}`);

    const { success, output: { _related: { ticket, social_links }, _misc: { type_information }, ...output } } = get(response, 'data', {});

    if (success === true) {
      let currentUser = '';

      setHandleBody(output);
      setTicket(ticket);
      setTicketId(get(ticket, 'id', null));
      setTicketHeaderAttachment(get(ticket, '_misc.attachments', []));
      setHandleTypeInformation(type_information);
      setSocialNetworksLinks(social_links);
      setHandleClaim(get(type_information, 'handle', {}));
      setTicketContent(ticket._related.ticket_content.map(content => {
        const { id } = user;
        const { name_user, created_by } = content;
        let isSameUser = true;
        const isSupport = created_by !== id;
        if (currentUser === '' || currentUser !== name_user) {
          currentUser = name_user;
          isSameUser = false;
        }
        return {
          ...handleTypeInformation,
          ...content,
          isSameUser,
          isSupport
        };
      }));
    }

    setLoading(false);
    setIsFirstLoad(false);
    resetLoadingState();
  };
  const submitClaimsDetails = async formData => {
    const { text } = formData;
    if (!formData || !token) {
      return;
    }
    setLoadingSubmit(true);
    const response = await http(apiHost, token.accessToken, token.tokenType)
    .post('/api/web/v2/support/support-body', {
      id_head: ticketId,
      text
    })
    .catch(err => {
      console.log(err);
      notificationError(err.message);
    });
    const { success, output, message } = get(response, 'data', {});
    if (success) {
      setSuccessfullyMessageSubmitted({ success, output, message });
      // notificationError('There was an error submitting your message.');
    }
  };
  const submitLinkAttachment = async (bodyId, attachmentId) => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
    .post('/api/web/v2/support/create-attachment', {
      ticket_head_id: ticketId,
      ticket_body_id: bodyId,
      media_id: attachmentId
    })
    .catch(err => {
      console.log(err);
      resetFormState(err.message);
    });

    if (response) {
      const { success } = get(response, 'data', {});
      if (success === true) {
        notificationSuccess(`Attachment successfully Linked.`);
      } else {
        notificationError(`Error uploading file.`);
      }
    } else {
      resetFormState('Something went wrong');
    }
  };
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
// Load Claims
  useEffect(() => {
    if (reloadClaimsContent === true) {
      if (successfullyMessageSubmitted?.success === true) {
        resetFormState('Message successfully submitted.', 'success');
      }
      setTimeout(() => {
        loadClaim().catch(err => console.log(err));
      }, 1000);
    }
  }, [reloadClaimsContent]);
// Submit Attachments
  useEffect(() => {
    if (successfullyMessageSubmitted && successfullyMessageSubmitted.success === true) {
      if (formAttachments.length) {
        submitChatAttachments().catch(err => {
          console.log('attachment', err);
          notificationError(err.message);
        });
      } else {
        setReloadClaimsContent(true);
      }
    }
  }, [successfullyMessageSubmitted]);
// uploadedAttachments
  useEffect(() => {
    if (
      uploadedAttachments.length &&
      successfullyMessageSubmitted?.success
    ) {
      const successLink = uploadedAttachments.map(uploadedAttachment => {
        const { success, data } = uploadedAttachment;
        const { output } = successfullyMessageSubmitted;
        if (success === true &&
          output &&
          output.id &&
          data
        ) {
          if (data.files) {
            resetFormState(data.files[0].error);
          } else if (data[0] && data[0].id) {
            submitLinkAttachment(output.id, data[0].id)
            .catch(err => {
              console.log(err);
              resetFormState(err.message);
            });
          }
        }
        return success;
      });

      if (successLink.length === uploadedAttachments.length) {
        setReloadClaimsContent(true);
      }
    }
  }, [uploadedAttachments]);

  return (
    <ClaimsLayout
      pageClassName="ClaimsDetails"
      title={id ? `Claim Review` : `Create Claim`}
      breadcrumb={[
        <Link
          onClick={() => {
            navigate('/claims');
          }}
        >
          Home
        </Link>,
        `Claim ${handleClaim}`
      ]}
    >
      <FormProvider {...chatMessageForm}>
        <Paper
          elevation={2}
          className="pj-claims-details__chat"
          sx={
            {
              borderRadius: picklejarBorderRadius,
              padding: { md: picklejarBorderRadius },
              mt: 3,
              mb: 5,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              boxShadow: { xs: 'unset', md: picklejarBoxShadow }
            }
          }
        >
          {loading && !loadingSubmit && isFirstLoad ?
            <Grid
              container
              justifyContent="center"
            ><CircularProgress sx={{ mt: 5, mb: 5, alignSelf: 'center' }} /> </Grid> :
            <>
              <Box
                className="pj-claims-details__chat-content"
                component="div"
              >
                <List
                  disablePadding
                  className="pj-claims-details__chat-header"
                >
                  <ListItem
                    alignItems="flex-start"
                    disablePadding
                    className="pj-claims-details__chat-header-list-item"
                  >
                    <ListItemAvatar className="pj-claims-details__chat-avatar-item">
                      <Avatar
                        src={get(handleTypeInformation, '_misc.avatar.small_image')}
                        alt={get(handleTypeInformation, 'stage_name')}
                        sx={{ width: picklejarAvatarSizeLg, height: picklejarAvatarSizeLg, minWidth: 'unset' }}
                      />
                      <ListItemText
                        sx={{ ml: 2 }}
                        primary={<Typography variant="h6">{handleClaim}</Typography>}
                        secondary={
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.secondary"
                          >
                            {get(handleTypeInformation, 'stage_name')}
                          </Typography>
                        }
                      />
                      {renderSocialLinks()}
                    </ListItemAvatar>
                    <ClaimsChips
                      status={status}
                      isRejected={get(ticket, 'is_rejected', false)}
                      isApproved={get(ticket, 'is_approved', false)}
                    />
                  </ListItem>
                </List>
                <Divider sx={{ mt: 1, mb: 0 }} />

                <Box
                  sx={{ mx: 0, mb: 4, p: 3 }}
                  bgcolor={picklejarTextColorGray}
                >
                  <Typography variant="h5">Subject:</Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >{get(handleBody, 'request_comments')}</Typography>
                  {
                    ticketHeaderAttachment.length > 0 &&
                    (
                      <AttachmentList
                        attachmentList={ticketHeaderAttachment}
                        uploadFiles={false}
                      />
                    )
                  }
                </Box>
                {
                  ticketContent.length > 0 &&
                  (
                    <List
                      sx={{
                        maxHeight: { xs: 'unset', md: '400px' },
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        boxShadow: { xs: 'unset', md: picklejarBoxShadow },
                        borderRadius: '1.5rem',
                        paddingBottom: '1.5rem'
                      }}
                    >
                      {
                        ticketContent.map(content =>
                          <ClaimsMessages
                            key={content.id}
                            content={content}
                            isSameUser={content.isSameUser}
                            isSupport={content.isSupport}
                            uploadFiles={false}
                          />)
                      }
                    </List>
                  )
                }
                {
                  reloadClaimsContent &&
                  (
                    <Grid
                      container
                      justifyContent="center"
                    ><CircularProgress sx={{ mt: 5, mb: 5, alignSelf: 'center' }} /> </Grid>
                  )
                }
              </Box>

              {
                status?.toLowerCase() !== 'closed' &&
                <Box
                  component="form"
                  onSubmit={handleSubmit((onSubmit, onError) => submitClaimsDetails(onSubmit, onError))}
                  autoComplete="off"
                  sx={{
                    borderTop: `solid thin ${picklejarBorderInputColor}`,
                    position: { xs: 'fixed', md: 'relative' },
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: picklejarLightBackground
                  }}
                  className="claims-details__form"
                >
                  {
                    formAttachments.length > 0 &&
                    (
                      <AttachmentList
                        key="attachmentList"
                        attachmentList={formAttachments}
                        uploadAttachments
                        removeAttachmentCallback={removeAttachment}
                        transferCompletedCallback={uploadAttachmentCompleted}
                        isLoading={loading || loadingSubmit}
                        uploadFiles={uploadFiles}
                      />
                    )
                  }

                  <div className="input-control pj-d-flex">
                    <Controller
                      name="attachment"
                      control={control}
                      render={({ field }) => (
                        <IconButton
                          color="inherit"
                          aria-label="upload picture"
                          component="label"
                          sx={{ mx: 2, mt: 1 }}
                          disabled={loading || loadingSubmit}
                        >
                          <TextField
                            {...field}
                            disabled={loading || loadingSubmit}
                            type="file"
                            label=""
                            className="claims-details__attachment"
                            sx={{
                              backgroundColor: picklejarLightBackground,
                              mb: 0
                            }}
                            inputProps={{
                              multiple: true,
                              accept: 'application/pdf, image/png, image/jpg, image/jpeg'
                            }}
                            hidden
                            onChange={onChange}
                          />
                          <Attachment />
                        </IconButton>
                      )}
                    />

                    <Controller
                      name="text"
                      control={control}
                      rules={{ required: 'Message Is Required' }}
                      render={({ field }) =>
                        <TextField
                          {...field}
                          label="Message"
                          className="claims-details__messages"
                          sx={{
                            backgroundColor: picklejarLightBackground,
                            mb: 0,
                            mt: 1
                          }}
                          disabled={loadingSubmit}
                        />
                      }
                    />
                    <IconButton
                      type="submit"
                      sx={{ ml: 2, mt: 1, borderRadius: '50%' }}
                      variant="contained"
                      color="primary"
                      disabled={loadingSubmit}
                    >
                      {!loadingSubmit ? <Send /> :
                        <CircularProgress
                          color="primary"
                          style={{ width: '24px', height: '24px' }}
                        />
                      }
                    </IconButton>
                  </div>
                </Box>
              }
            </>
          }
        </Paper>
      </FormProvider>
    </ClaimsLayout>
  );
}

ClaimsDetails.defaultProps = {
  id: null,
  is_deleted: null,
  owner_class: '',
  owner_id: null,
  user_id: null,
  department_id: null,
  topic: '',
  status: '',
  notes: '',
  created_at: null,
  updated_at: null,
  created_by: null,
  updated_by: null,
  _related: null,
  _misc: null,
  _links: null
};

ClaimsDetails.propTypes = {
  id: PropTypes.number,
  is_deleted: PropTypes.bool,
  owner_class: PropTypes.string,
  owner_id: PropTypes.number,
  user_id: PropTypes.number,
  department_id: PropTypes.number,
  topic: PropTypes.string,
  status: PropTypes.string,
  notes: PropTypes.string,
  created_at: PropTypes.number,
  updated_at: PropTypes.number,
  created_by: PropTypes.number,
  updated_by: PropTypes.number,
  _related: PropTypes.shape({
    user: PropTypes.shape(UserModel),
    department: PropTypes.shape(DepartmentModel),
    ticket: [TicketModel],
    social_links: [SocialLinksModel]
  }),
  _misc: PropTypes.shape({
    attachments: [AttachmentModel],
    type_information: ClaimsExtraInformation
  }),
  _links: PropTypes.shape({
    self: PropTypes.shape({
      href: PropTypes.string
    })
  })
};

export default ClaimsDetails;

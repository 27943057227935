/* eslint-disable camelcase */
// Packages
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Button, CircularProgress, Container, Divider, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import get from "lodash/get";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ArrowBack, Close } from "@mui/icons-material";
import { isEmpty } from "lodash";

// Relatives
import http from "../../services/api/http";
import AppContext from "../../contexts/AppContext";
import UserContext from "../../contexts/UserContext";
import UserProfileInformation from "./UserProfileInformation";
import UserProfilePersonalData from "./UserProfilePersonalData";
import { PICKLEJAR_DEFAULT_AVATAR_IMAGE, PICKLEJAR_DEFAULT_EDIT_ICON } from "../../const/ClaimsTheme";
import UserProfileImageForm from "./components/UserProfileImageForm";
import UserProfileProveVerifyForm from "./components/UserProfileProveVerifyForm";
import NotificationContext from "../../contexts/NotificationContext";
import LoginLayout from "../../layouts/Login";
import LayoutContext from "../../contexts/LayoutContext";
import { PICKLEJAR_DEFAULT_GO_BACK_PROFILE_PAGE_URL } from "../../const/PicklejarSettings";
import UserProfileBirthDayGenderForm from "./components/UserProfileBirthDayGenderForm";
import UserProfileGenresForm from "./components/UserProfileGenresForm";
import UserProfileMusicGenres from "./UserProfileMusicGenres";

export const UserProfileDetails = () => {
  const [loading, setLoading] = useState(false);
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token, params } = useContext(UserContext);
  const { notificationError, notificationSuccess } = useContext(NotificationContext);
  const {
    setHeader,
    setSubtitle,
    setPageTitle,
    setPageClass,
    setHideLogo
  } = useContext(LayoutContext);
  /**
   * App State
   */
  const navigate = useNavigate();
  const [editModeOn, setEditModeOn] = useState(true);
  const [userParams, setUserParams] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [avatarImage, setAvatarImage] = useState(PICKLEJAR_DEFAULT_AVATAR_IMAGE);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openPersonalModal, setOpenPersonalModal] = useState(false);
  const [openGenresModal, setOpenGenresModal] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const mobilePhone = get(params, 'profile.phone_1', []);
  /**
   * Handlers
   */
  const handleOpenAddressModal = () => setOpenAddressModal(true);
  const handleCloseAddressModal = () => setOpenAddressModal(false);
  const handleOpenPersonalModal = () => setOpenPersonalModal(true);
  const handleClosePersonalModal = () => setOpenPersonalModal(false);
  const handleOpenGenresModal = () => setOpenGenresModal(true);
  const handleCloseGenresModal = () => setOpenGenresModal(false);
  const updateUserData = data => {
    const {
      profile: {
        firstName,
        lastName,
        avatar: { largeImage },
        ...profile
      },
      address,
      email,
      genreDetails,
      proveEligibility,
      proveIdentity,
      proveVerified
    } = data;

    setAvatarImage(largeImage)

    setUserParams({
      firstName,
      lastName,
      addresses: [address],
      emailAddresses: [email]
    });

    setUserProfile({
      ...profile,
      firstName,
      lastName,
      handle: params.handle,
      genreDetails,
      proveEligibility,
      proveIdentity,
      proveVerified,
      address
    });
  }
  const processResponses = (success, data, error, successMessage, errorMessage) => {
    if (success === true && data) {
      updateUserData(data);
      if (successMessage) {
        notificationSuccess(successMessage);
      }
    }

    if (!isEmpty(error)) {
      if (errorMessage) {
        notificationError(errorMessage);
      }

    }

    setLoading(false);
  }
  /**
   * Fetch Api
   */
  const submitAddressForm = (success, data, error) => {
    processResponses(success, data, error, 'Address updated successfully', 'Address failed to update')
    handleCloseAddressModal();
  };
  const submitBirthdayGenderForm = (success, data, error) => {
    processResponses(success, data, error, 'Profile updated successfully', 'Profile failed to update')
    handleClosePersonalModal();
  };
  const submitMusicGenresForm = (success, data, error) => {
    processResponses(success, data, error, 'Music Genres updated successfully', 'Music Genres failed to update')
    handleCloseGenresModal();
  };
  const fetchUserData = async () => {
    setLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType)
    .post('/api/web/v1/auth/update-profile',
      {
        username: params.handle
      }
    )
    .catch(() => {
    });

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { data } = get(response, 'data', {});
    updateUserData(data);
  }
  /**
   * Effects
   */
  useEffect(() => {
    setUserProfile(userProfile);
    setShowEditButton(
      userProfile.proveEligibility === false ||
      (userProfile.proveEligibility === true && userProfile.proveVerified === false)
    );
  }, [userProfile])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setHideLogo(true);
    setPageTitle(
      showEditButton === true ?
        'Profile Details' :
        'VerifyMe'
    );
    setPageClass('ProfileDetails');
    setHeader(() =>
      <Typography
        color="primary"
        variant="h1"
        className="pj-login-header"
      >Profile Details</Typography>
    );
    setSubtitle(() =>
      <Typography
        variant="body2"
        className="pj-login-subtitle"
      >Update your account</Typography>
    );
  }, [])
  /**
   * Api Requests
   */
  useEffect(() => {
    fetchUserData().catch(err => console.log(err));
  }, []);

  return (
    <LoginLayout>
      <Box className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </Box>
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12} textAlign="start">
            <Button
              type="button"
              onClick={() => navigate(PICKLEJAR_DEFAULT_GO_BACK_PROFILE_PAGE_URL)}
              color="primary"
              size="small"
              variant="text"
              startIcon={<ArrowBack />}
              disabled={loading}
              sx={{ mt: 5, mb: 3 }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12}>
            <List dense>
              <ListItem disablePadding>
                <ListItemText
                  disableTypography
                  primary={<Typography variant="labels">Profile Image</Typography>}
                  secondary={
                    <UserProfileImageForm
                      onSubmit={null}
                      loadingAvatar={loading}
                      setLoadingAvatar={setLoading}
                      profileData={userProfile}
                      defaultPreviewUrl={avatarImage}
                      canEdit
                    />
                  }
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h4">Genres</Typography>
              <Button
                type="button"
                variant="text"
                color="primary"
                size="small"
                disabled={loading === true}
                onClick={handleOpenGenresModal}
                startIcon={<img src={PICKLEJAR_DEFAULT_EDIT_ICON} alt="edit icon" />}
              >
                Edit
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} textAlign="start">
            <Divider variant="fullWidth" color="white" />
            <UserProfileMusicGenres profileData={userProfile} />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h4">Personal Information</Typography>
              {
                showEditButton === true &&
                (
                  <Button
                    type="button"
                    variant="text"
                    color="primary"
                    size="small"
                    disabled={loading === true}
                    onClick={handleOpenPersonalModal}
                    startIcon={<img src={PICKLEJAR_DEFAULT_EDIT_ICON} alt="edit icon" />}
                  >
                    Edit
                  </Button>
                )
              }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" color="white" />
            <UserProfilePersonalData profileData={userProfile} />
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h4">Address</Typography>

              {
                showEditButton &&
                (
                  <Button
                    type="button"
                    variant="text"
                    color="primary"
                    size="small"
                    disabled={loading === true}
                    onClick={handleOpenAddressModal}
                    startIcon={<img src={PICKLEJAR_DEFAULT_EDIT_ICON} alt="edit icon" />}
                  >
                    Edit
                  </Button>
                )
              }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" color="white" />
            <UserProfileInformation params={userParams} mobilePhone={mobilePhone} />
          </Grid>
        </Grid>
      </Container>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openAddressModal}
        onClose={handleCloseAddressModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Edit Address
          <Button
            onClick={handleCloseAddressModal}
            variant="text"
          >
            <Close />
          </Button>
        </DialogTitle>
        <DialogContent>
          <UserProfileProveVerifyForm
            editModeOn={editModeOn}
            loading={loading}
            isProfilePage
            onSubmit={submitAddressForm}
            response={userProfile}
            setEditModeOn={setEditModeOn}
            setLoading={setLoading}
            showInModal
            submitButtonLabel="Update"
          />
          <List>
            <ListItem>
              <Button
                onClick={handleCloseAddressModal}
                fullWidth
                variant="outline"
              >
                Close
              </Button>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openPersonalModal}
        onClose={handleClosePersonalModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Edit User Information
          <Button
            onClick={handleClosePersonalModal}
            variant="text"
          >
            <Close />
          </Button>
        </DialogTitle>
        <DialogContent>
          <UserProfileBirthDayGenderForm
            formDefaultValues={userProfile}
            loading={loading}
            onSubmit={submitBirthdayGenderForm}
            setLoading={setLoading}
            isProfilePage
            showInModal
          />
          <List>
            <ListItem>
              <Button
                onClick={handleClosePersonalModal}
                fullWidth
                variant="outline"
                sx={{ margin: '0 auto' }}
              >
                Close
              </Button>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openGenresModal}
        onClose={handleCloseGenresModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Edit Genres
          <Button
            onClick={handleCloseGenresModal}
            variant="text"
          >
            <Close />
          </Button>
        </DialogTitle>
        <DialogContent>
          <UserProfileGenresForm
            defaultGenres={userProfile.genreDetails}
            loading={loading}
            onSubmit={submitMusicGenresForm}
            setLoading={setLoading}
            showInModal
          />
          <List>
            <ListItem>
              <Button
                onClick={handleCloseGenresModal}
                fullWidth
                variant="outline"
                sx={{ margin: '0 auto' }}
              >
                Close
              </Button>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
    </LoginLayout>
  )
}

UserProfileDetails.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  id: null,
  updateMode: false
};

UserProfileDetails.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
  updateMode: PropTypes.bool,
  profilePageUrl: PropTypes.string.isRequired
};


export default UserProfileDetails;

import axios from 'axios';

const http = (baseURL, token, mode = 'bearer', headers = {}, uploadProgress) => {
  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...headers
  };

  if (token && mode === 'bearer') {
    headers.Authorization = `Bearer ${token}`;
  } else if (token) {
    headers.Authorization = token;
  }

  const checkStatus = status => {
    if (status < 401) {
      return true;
    }
    
    if (status === 401) {
      window.localStorage.clear();
      window.location.reload();
    } 

    return false;
  }

  return axios.create({
    baseURL,
    withCredentials: false,
    responseType: 'json',
    validateStatus: status => checkStatus(status), // Resolve only if the status code is less than 400,
    timeout: 0,
    headers,
    onUploadProgress: uploadProgress
  });
};

export default http;

/* eslint-disable camelcase */
// Packages
import * as React from 'react';
import PropTypes from 'prop-types';
import { Avatar, ListItemAvatar } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';

// Relative
import { ClaimsExtraInformation } from '../../models/ClaimsExtraInformation';
import ClaimsChips from '../ClaimsChips';
import { picklejarTextColorDark } from '../../../../const/ClaimsTheme';
import { RenderClaimsAvatar, RenderClaimsBanner } from '../../../../components/common/utils/RenderImages';


const ClaimsCards = props => {
  const navigate = useNavigate();
  const { data, actions } = props;
  const banner = RenderClaimsBanner(data);
  const avatar = RenderClaimsAvatar(data);
  console.log(props);
  const id = get(data, 'id', null);
  const is_approved = get(data, 'is_approved', false);
  const is_rejected = get(data, 'is_rejected', false);
  const status = get(data, '_related.ticket.status', 'open');
  const handle = get(data, '_misc.claim_extra_information.handle', '');
  const stage_name = get(data, '_misc.claim_extra_information.stage_name', null);

  return (
    <Card
      sx={{ borderRadius: '14px' }}
      className="claim-card"
      key={`claims-details-claims-card-${id}`}
    >
      <CardContent
        sx={
          {
            alignItems: 'space-between',
            flexDirection: 'column'
          }
        }
      >
        <img
          src={banner}
          alt="claim background"
          className="background"
          style={(
            actions === true
          ) ? { filter: 'grayscale(0)' } : { filter: 'grayscale(1)' }}
        />
        <div className="overlay"/>

        <ClaimsChips
          isApproved={is_approved}
          isRejected={is_rejected}
          status={status}
        />

        <ListItemAvatar
          className="avatar-holder justify-content-start"
          sx={{ mt: 4, me: 3 }}
        >
          <Avatar
            alt={stage_name}
            src={avatar}
            className="avatar-image"
            variant="circular"
            sx={{ width: 40, height: 40 }}
          />
          <div
            className="pj-d-flex avatar-name mx-2"
            style={(
              actions === true
            ) ? { filter: 'grayscale(0)' } : { filter: 'grayscale(1)' }}
          >
            <strong className={actions === true ? 'text-ellipsis pj-text-primary' : 'text-ellipsis text-light'}>
              {handle || 'Anonymous'}
            </strong>
          </div>
        </ListItemAvatar>
      </CardContent>
      {actions ?
        <CardActions sx={{ justifyContent: 'center', color: picklejarTextColorDark }}>
          <Button
            disableRipple
            type="button"
            onClick={() => {
              navigate(`/claims/edit/${id}`);
            }}
            startIcon={<Edit/>}
            size="small"
            color="inherit"
          >
            Review Claim
          </Button>
        </CardActions> : null
      }
    </Card>
  );
};

ClaimsCards.defaultProps = {
  type: null,
  actions: false,
  data: []
};


export const ClaimsModel = {
  created_at: PropTypes.number,
  created_by: PropTypes.number,
  department_id: PropTypes.number,
  id: PropTypes.number,
  is_deleted: PropTypes.bool,
  notes: PropTypes.string,
  owner_class: PropTypes.string,
  owner_id: PropTypes.number,
  status: PropTypes.string,
  topic: PropTypes.string,
  updated_at: PropTypes.number,
  updated_by: PropTypes.number,
  user_id: PropTypes.number,
  _misc: PropTypes.shape({
    claim_extra_information: PropTypes.shape(ClaimsExtraInformation)
  })
};

ClaimsCards.propTypes = {
  type: PropTypes.string,
  actions: PropTypes.bool,
  data: PropTypes.shape(ClaimsModel)
};

export default ClaimsCards;

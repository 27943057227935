import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
// Relatives
import NotificationContext from './NotificationContext';
import NOTIFICATION_STATES from './NotificationStates';

const NotificationProvider = props => {
  const { children } = props;
  /**
   * App State
   */
  const [notification, setNotification] = useState(null);
  const [notificationText, setNotificationText] = useState(null);
  const notificationSuccess = text => {
    notificationClear();
    setTimeout(() => {
    setNotificationText(text);
    setNotification(NOTIFICATION_STATES.SUCCESS);
    }, 500);
  };
  const notificationError = text => {
    notificationClear();
    setTimeout(() => {
    setNotificationText(text);
    setNotification(NOTIFICATION_STATES.ERROR);
    }, 500);
  };
  const notificationClear = () => {
    setNotificationText(null);
    setNotification(null);
  };
  const NotificationnValueMemo = useMemo(
    () => (
      {
        notification,
        setNotification,
        notificationText,
        setNotificationText,
        notificationSuccess,
        notificationError,
        notificationClear
      }
    ),
    [
      notification,
      setNotification,
      notificationText,
      setNotificationText,
      notificationSuccess,
      notificationError,
      notificationClear
    ]
  );

  return (
    <NotificationContext.Provider value={NotificationnValueMemo}>{children}</NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NotificationProvider;

/* eslint-disable camelcase */
/* eslint-disable operator-linebreak */
// Packages
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Add, ArrowBack, Attachment, Delete, Save } from '@mui/icons-material';
import { debounce, get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@mui/material/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

// Relatives
import ClaimsLayout from '../../layouts/Claims/ClaimsLayout';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import { picklejarBorderRadius, picklejarLightBackground, picklejarPrimaryColor, picklejarTextColorDark } from '../../const/ClaimsTheme';
import { CreateAttachmentData, RemoveAttachmentData } from '../../components/common/attachments/AttachmentData';
import AttachmentList from '../../components/common/attachments';
import { RegexUrl } from '../../components/common/utils/RegexUrl';

// eslint-disable-next-line
const ClaimsCreate = props => {
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const { notificationSuccess, notificationError } = useContext(NotificationContext);
  const navigate = useNavigate();
  /**
   * App State
   */
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
  const [handleList, setHandleList] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [handleInputValue, setHandleInputValue] = useState('');
  const [handleValue, setHandleValue] = useState(null);
  const [navigateToClaimDetailsAttachments, setNavigateToClaimDetailsAttachments] = useState(false);
  const [navigateToClaimDetailsSocialLinks, setNavigateToClaimDetailsSocialLinks] = useState(false);
  const [updateSocialIcon, setUpdateSocialIcon] = useState({ value: null, index: null });
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  /**
   * Effect states
   */
  const [claimData, setClaimData] = useState({});
  const [formAttachments, setFormAttachments] = useState([]);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [updatedSocialMediaLinks, setUpdatedSocialMediaLinks] = useState([]);
  const [newClaimAttachments, setNewClaimAttachments] = useState([]);
  const [socialNetworksList, setSocialNetworksList] = useState([]);
  const [socialNetworksInputList, setSocialNetworksInputList] = useState([]);
  /**
   * Variables
   *
   */
  const claimsForm = useForm({
    defaultValues: {
      handle: '',
      request_comments: '',
      socialNetworks: []
    }
  });
  const { control, handleSubmit, setFocus, setValue } = claimsForm;
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'socialNetworks'
  });
  const watchFieldArray = useWatch({
    control,
    name: 'socialNetworks' // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    };
  });
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250
      }
    }
  };
  const selectedHandle = useWatch({ control, name: 'handle' });
  const [uploadFiles, setUploadFiles] = useState(false);
  /**
   * Handlers
   */
  const attachmentFieldOnChange = event => {
    setFormAttachments([...formAttachments, ...CreateAttachmentData([...event.target.files])]);
  };
  const removeAttachment = index => {
    setFormAttachments(RemoveAttachmentData(formAttachments, index));
  };
  const uploadAttachmentCompleted = (totalAttachments, totalSuccess) => {
    setLoadingSubmit(false);
    if (formAttachments.length === totalAttachments.length) {
      if (formAttachments.length === totalSuccess.length) {
        setUploadedAttachments(totalAttachments);
      } else {
        notificationError('There was an error with some attachments. Please try again');
      }
    }
  };
  const filterOptions = createFilterOptions({
    ignoreAccents: true,
    ignoreCase: true
  });
  const updateAutocompleteValueOnChange = (event, newValue) => {
    setHandleValue(newValue);
    if (newValue !== '') {
      setOptionList(newValue ? [newValue, ...optionList] : optionList);
    } else {
      setOptionList([]);
    }
    setValue('handle', newValue ? newValue.id : '');
    setLoading(false);
  };
  const updateAutocompleteValueOnInputChange = (event, newInputValue) => {
    setHandleInputValue(newInputValue);
  };
  const resetFormState = (message, type = 'error') => {
    if (message) {
      switch (type) {
        case 'error':
          notificationError(message);
          break;
        case 'success':
          notificationSuccess(message);
          break;
        default:
          notificationError(message);
      }
      setLoadingSubmit(false);
      setSuccessfullySubmitted(false);
    }
  };
  /**
   * Api Requests
   */
  const fetchClaims = useMemo(() => debounce(request => {
    loadHandles(request).catch(err => {
      console.log(err);
      return resetFormState(err.message);
    });
  }, 1000), []);
  const loadHandles = async value => {
    if (value) {
      setLoading(true);
      const response = await http(apiHost, token.accessToken, token.tokenType)
        .get(`/api/web/v1/search-handle/search?handle=${value}&exclude_business=1&exclude_bands=1&exclude_child_labels=1&exclude_users=1&exclude_labels=1&page=1&fields=artist.id, handle, name, stageName, user, artist.user.id, artist.genreDetails, band, label, childLabel, childUserId, avatar&exclude_venue_artists=1&exclude_personality_artists=1&exclude_broadcaster_artists=1&exclude_hospitality_artists=1&exclude_music_pro_artists=1&exclude_ngo_artists=1&exclude_logged_user=1&is_claimable=1`);
      const { success, data } = get(response, 'data', {});
      if (success === true) {
        setHandleList(data);
      }
    } else {
      setHandleList([]);
    }
    setLoading(false);
  };
  const loadSocialNetworks = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v1/social-link/`);
    const { success, data } = get(response, 'data', {});
    if (success === true) {
      setSocialNetworksList(data);
    }
  };
  const submitNewClaim = async formData => {
    setLoadingSubmit(true);
    const { request_comments } = formData;
    if (!formData || !token) {
      return;
    }
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v2/ownership-change-request', {
        type: 'artist',
        model_id: formData.handle,
        request_comments
      })
      .catch(err => {
        console.log(err);
        resetFormState(err.message);
      });

    const { success, output, message } = get(response, 'data', {});
    setClaimData({ success, output, message });
  };
  const uploadMedia = async () => {
    if (!token) {
      return;
    }
    setLoadingSubmit(true);
    setUploadFiles(true);
  };
  const submitLinkAttachment = async (ticket_head_id, media_id) => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v2/support/create-attachment', {
        ticket_head_id,
        ticket_body_id: null,
        media_id
      });

    if (response) {
      const { success, output, message } = get(response, 'data', {});
      setNewClaimAttachments([...newClaimAttachments, { success, output, message }]);
    }
  };
  const submitSocialNetworks = async inputData => {
    const { socialId, link } = inputData;
    const { output: { id } } = claimData;
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v2/ownership-change-request/create-social-link', {
        social_link_id: socialId,
        ownership_change_request_id: Number(id),
        owner_class: 'common\\models\\Artist',
        owner_id: claimsForm.getValues('handle').toString(),
        link
      });

    if (response) {
      const { success, output, message } = get(response, 'data', {});
      setUpdatedSocialMediaLinks([...updatedSocialMediaLinks, { success, output, message }]);
    }
  };
  const redirectUser = () => {
    setShowCompleteModal(false);
    navigate(`/claims/edit/${claimData.output.id}`);
  };
  /**
   * Effects
   */
  // Handle events
  useEffect(() => {
    let active = true;
    if (active) {
      if (handleInputValue === '') {
        setOptionList(handleValue ? [handleValue] : []);
        setLoading(false);
        return undefined;
      }

      if (isNull(handleValue)) {
        fetchClaims(handleInputValue, results => {
          if (active) {
            let newOptions = [];

            if (handleValue) {
              newOptions = [handleValue];
            }

            if (results) {
              newOptions = [...newOptions, ...results];
            }
            setOptionList(newOptions);
          }
        });
      }
    }

    return () => {
      active = false;
    };
  }, [handleValue, handleInputValue, fetch]);
  // Handles list
  useEffect(() => {
    const object = [...handleList].map(item => (
      { label: item.handle, id: item.artist.id }
    ));
    setOptionList(object);
  }, [handleList]);
  // Load Social Networks
  useEffect(() => {
    if (socialNetworksList.length === 0) {
      loadSocialNetworks().catch(err => {
        console.log(err);
        resetFormState(err.message);
      });
    }
  }, [socialNetworksList]);
  // Claim Data
  useEffect(() => {
    let subscribed = true;
    if (claimData && subscribed) {
      const { success, output, message } = claimData;
      if (success === true) {
        if (formAttachments.length || claimsForm.getValues('socialNetworks').length) {
          if (formAttachments.length) {
            uploadMedia().catch(err => {
              console.log(err);
              resetFormState(`${message}: ${err.message}`);
            });
          } else {
            setNavigateToClaimDetailsAttachments(true);
          }

          if (claimsForm.getValues('socialNetworks').length) {
            claimsForm.getValues('socialNetworks').forEach(socialNetwork => submitSocialNetworks(socialNetwork));
          } else {
            setNavigateToClaimDetailsSocialLinks(true);
          }
        } else {
          setNavigateToClaimDetailsAttachments(true);
          setNavigateToClaimDetailsSocialLinks(true);
        }
      } else if (message) {
        resetFormState(`${message}: ${output?.model_id?.[0]}`);
      }
    }

    return () => {
      subscribed = false;
    };
  }, [claimData]);
  // Upload Attachments
  useEffect(() => {
    if (uploadedAttachments.length) {
      const successLink = uploadedAttachments.map(uploadedAttachment => {
        const { success, data } = uploadedAttachment;
        const { output } = claimData;
        if (success === true) {
          submitLinkAttachment(output._related.ticket.id, data?.[0]?.id)
            .catch(err => {
              console.log(err);
              resetFormState(err.message);
            });
        }
        return success;
      });

      if (successLink.length === uploadedAttachments.length) {
        setNavigateToClaimDetailsAttachments(true);
      }
    }
  }, [uploadedAttachments]);
  // Navigate to success details
  useEffect(() => {
    if (navigateToClaimDetailsAttachments === true && navigateToClaimDetailsSocialLinks === true) {
      resetFormState('Claim successfully created.', 'success');
      setShowCompleteModal(true);
    }
  }, [navigateToClaimDetailsAttachments, navigateToClaimDetailsSocialLinks]);
  // Update social network icons
  useEffect(() => {
    const { value, index } = updateSocialIcon;

    if (value !== null) {
      try {
        let otherContent = null;
        let matchContent = null;
        socialNetworksList.every(socialNetwork => {
          const { id, name } = socialNetwork;
          const nameLowercase = name.toLowerCase();
          const icon = `/images/icons/socialnetworks/${nameLowercase}.svg`;
          if (value.toLowerCase().includes(`${nameLowercase}.com`)) {
            matchContent = { name: value, link: value, socialId: id, icon };
            return false;
          }
          if (name === 'Other') {
            otherContent = { name: value, link: value, socialId: id, icon };
            return false;
          }
          return true;
        });

        if (matchContent) {
          update(index, matchContent);
        } else {
          update(index, otherContent);
        }
      } catch (err) {
        console.log(err);
      }
      const latestValue = claimsForm.getValues(`socialNetworks.${index}`);

      if (!socialNetworksInputList.length) {
        setSocialNetworksInputList([latestValue]);
      } else {
        const socialNetworksInputListDestructive = [...socialNetworksInputList];
        socialNetworksInputListDestructive[index] = claimsForm.getValues(`socialNetworks.${index}`);
        setSocialNetworksInputList(socialNetworksInputListDestructive);
        if (
          socialNetworksInputListDestructive.length &&
          socialNetworksInputList[index] &&
          socialNetworksInputList[index].icon.toLowerCase() !== latestValue.icon.toLowerCase()
        ) {
          setTimeout(() => {
            setFocus(`socialNetworks.${index}.name`);
          }, 1);
        }
      }
    }
  }, [updateSocialIcon]);
  // Update social icons
  useEffect(() => {
    if (updatedSocialMediaLinks.length) {
      const successLink = updatedSocialMediaLinks.map(uploadedAttachment => {
        const { success } = uploadedAttachment;
        return success;
      });
      if (successLink.length === updatedSocialMediaLinks.length) {
        setNavigateToClaimDetailsSocialLinks(true);
      }
    }
  }, [updatedSocialMediaLinks]);

  return (
    <ClaimsLayout
      title="Create Claim"
    >
      <Box className="ClaimsCreate">
        <Grid
          component="div"
          container
          spacing={0}
        >
          <Button
            type="button"
            onClick={() => navigate('/claims')}
            color="primary"
            size="small"
            variant="text"
            startIcon={<ArrowBack/>}
            disabled={loading || loadingSubmit}
            sx={{ mt: 5 }}
          >
            Back
          </Button>
        </Grid>

        <FormProvider {...claimsForm}>

          <Paper
            elevation={2}
            sx={
              {
                borderRadius: picklejarBorderRadius,
                padding: picklejarBorderRadius,
                mt: 3,
                mb: 5
              }
            }
          >
            <Box
              component="form"
              onSubmit={handleSubmit((onSubmit, onError) => submitNewClaim(onSubmit, onError))}
              autoComplete="off"
            >
              <Grid
                component="div"
                container
                spacing={0}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h3">Claim @handle</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormGroup>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: '100%' }}
                      getOptionLabel={option => option.label}
                      filterOptions={filterOptions}
                      options={optionList}
                      loading={loading}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      value={handleValue}
                      onChange={updateAutocompleteValueOnChange}
                      onInputChange={updateAutocompleteValueOnInputChange}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="@handle"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? <CircularProgress
                                  color="inherit"
                                  size={20}
                                /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                      renderOption={(props, option) => {
                        const matches = match(option.label, handleInputValue, { insideWords: true });
                        const parts = parse(option.label, matches);

                        return (
                          <li {...props}>
                            <Grid
                              container
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs
                              >
                                {
                                  parts.map((part, index) => {
                                    return <span
                                      key={index}
                                      style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                        color: part.highlight ? picklejarPrimaryColor : picklejarTextColorDark
                                      }}
                                    >{part.text}</span>;
                                  })}
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                    />
                  </FormGroup>
                  <Controller
                    name="handle"
                    control={control}
                    rules={{ required: 'Handle is required' }}
                    render={({ field, fieldState }) =>
                      (
                        <>
                          <FormControl hidden>
                            <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                            <Select
                              {...field}
                              input={<OutlinedInput label="handle"/>}
                              MenuProps={MenuProps}
                              disabled={loading || loadingSubmit}
                            >
                              {handleList.map(({ handle, artist: { id } }) => (
                                <MenuItem
                                  key={id}
                                  value={id}
                                >
                                  {handle}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {fieldState.error && (
                                <Typography
                                  variant="p"
                                  color="error"
                                >
                                  {fieldState.error.message}
                                </Typography>
                              )}
                            </FormHelperText>
                          </FormControl>
                        </>
                      )
                    }
                  />
                </Grid>
              </Grid>

              {
                selectedHandle &&
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <Controller
                      name="request_comments"
                      control={control}
                      rules={{ required: 'Message description is required' }}
                      render={({ field, fieldState }) =>
                        <>
                          <Typography
                            paragraph={false}
                            variant="body1"
                          >
                            Why are you claiming this handle?
                          </Typography>
                          <TextField
                            fullWidth
                            {...field}
                            error={fieldState.invalid}
                            label="Message"
                            multiline
                            disabled={loading || loadingSubmit}
                            rows={2}
                            helperText={
                fieldState.error &&
                (
                  <Typography
                    variant="p"
                    color="error"
                  >
                    {fieldState.error.message}
                  </Typography>
                )
                            }
                          />
                        </>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    textAlign="right"
                    className="pj-attachments-form"
                  >
                    <Controller
                      name="attachment"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl sx={{ width: 'auto' }}>
                          <Typography variant="small">
                            Please provide a form of identification for our PickleJar team
                            to be able to verify your claim
                          </Typography>
                          <Button
                            variant="outlined"
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            type="button"
                            disabled={loading || loadingSubmit}
                            sx={{ my: 2, ml: 'auto' }}
                          >
                            Add Attachment
                            <TextField
                              {...field}
                              type="file"
                              label="Attachment"
                              error={fieldState.invalid}
                              sx={{ backgroundColor: picklejarLightBackground }}
                              inputProps={{
                                multiple: true,
                                accept: 'application/pdf, image/png, image/jpg, image/jpeg'
                              }}
                              hidden
                              disabled={loading || loadingSubmit}
                              onChange={attachmentFieldOnChange}
                            />
                            <Attachment/>
                          </Button>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    {
                      formAttachments.length > 0 &&
                      (
                        <AttachmentList
                          key="attachmentList"
                          attachmentList={formAttachments}
                          uploadAttachments
                          removeAttachmentCallback={removeAttachment}
                          transferCompletedCallback={uploadAttachmentCompleted}
                          isLoading={loading || loadingSubmit}
                          uploadFiles={uploadFiles}
                          className="pj-attachment-list"
                        />
                      )
                    }
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-between"
                    className="my-3 pj-social-networks-list"
                  >
                    <Typography variant="h4">
                      Social Networks
                    </Typography>

                    <Button
                      variant="outlined"
                      size="small"
                      key="social-networks-add-button"
                      disabled={loading || loadingSubmit}
                      onClick={() => {
                        append({ socialNetworks: '' });
                      }}
                    >
                      <Add/>
                    </Button>
                  </Grid>

                  {controlledFields.map((item, index) =>
                    <Box
                      component="div"
                      key={item.id}
                    >
                      <Controller
                        name={`socialNetworks.${index}.name`}
                        control={control}
                        key={`socialNetwork-${item.id}-controller`}
                        rules={{
                          required: 'Social Networks cannot be empty',
                          pattern: {
                            value: RegexUrl,
                            message: 'Missing URL Format'
                          }
                        }}
                        render={({ field: { ref, ...field }, fieldState }) =>
                          <Box
                            component="div"
                            className="input-control pj-d-flex"
                            key={`socialNetwork-${item.id}-div`}
                            sx={{ position: 'relative' }}
                          >
                            <TextField
                              {...field}
                              sx={{ mb: 0 }}
                              inputRef={ref}
                              key={`socialNetwork-${item.id}-input`}
                              label="Social Network"
                              value={field.value ? field.value.toLowerCase() : field.value}
                              InputProps={{
                                readOnly: loading || loadingSubmit,
                                startAdornment: <InputAdornment position="start">
                                  <img
                                    src={claimsForm.getValues(`socialNetworks.${index}.icon`)}
                                    alt={claimsForm.getValues(`socialNetworks.${index}.name`)}
                                    style={{
                                      width: '24px',
                                      height: '24px',
                                      alignSelf: 'center',
                                      marginRight: '8px',
                                      opacity: .5
                                    }}
                                  /></InputAdornment>
                              }}
                              onBlur={event => setUpdateSocialIcon({
                                value: event.target.value,
                                index
                              })}
                              error={fieldState.invalid}
                              helperText={
                                fieldState.error &&
                                (
                                  <Typography
                                    variant="p"
                                    color="error"
                                  >
                                    {fieldState.error.message}
                                  </Typography>
                                )
                              }
                            />
                            <IconButton
                              key={`socialNetwork-${item.id}-button`}
                              type="button"
                              onClick={() => remove(index)}
                              sx={{ ml: 2, mt: 1, alignSelf: 'start' }}
                              disabled={loading || loadingSubmit}
                            ><Delete/>
                            </IconButton>
                          </Box>
                        }
                      />

                      <Controller
                        hidden
                        name={`socialNetworks.${index}.icon`}
                        defaultValue="/images/icons/socialnetworks/other.svg"
                        control={control}
                        key={`socialNetwork-${item.id}-icon`}
                        render={({ field }) =>
                          <TextField
                            {...field}
                            hidden
                            key={`socialNetwork-${item.id}-icon-input`}
                            label="Social Network Icon"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        }
                      />

                      <Controller
                        hidden
                        name={`socialNetworks.${index}.socialId`}
                        defaultValue=""
                        control={control}
                        key={`socialNetworks-${index}-socialId`}
                        render={({ field }) =>
                          <TextField
                            {...field}
                            hidden
                            key={`socialNetworks-${index}-socialId-input`}
                            label="Social Network Id"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        }
                      />
                      <Controller
                        hidden
                        name={`socialNetworks.${index}.link`}
                        defaultValue=""
                        control={control}
                        key={`socialNetworks-${index}-link`}
                        render={({ field }) =>
                          <TextField
                            {...field}
                            hidden
                            key={`socialNetworks-${index}-link-input`}
                            label="Social Network Link"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        }
                      />
                    </Box>)
                  }

                  <Divider sx={{ mt: 5 }}/>

                  {
                    !successfullySubmitted &&
                    <Grid
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      container
                      spacing={2}
                      sx={{ mt: 4 }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={loading || loadingSubmit}
                      >
                        {!loadingSubmit ? <Save/> :
                          <CircularProgress
                            color="inherit"
                            style={{ width: '24px', height: '24px' }}
                          />
                        }

                        {!loadingSubmit ? ' Claim' : ''}
                      </Button>
                    </Grid>
                  }
                </>
              }
            </Box>
          </Paper>
        </FormProvider>
      </Box>

      <Dialog open={showCompleteModal}>
        <DialogTitle>
          Claim Successfully Submitted
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Thank you for submitting your claim. Our team will review your claim within the next 24 hours.
            We will reach out if any additional information is needed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={redirectUser}
            fullWidth
            sx={{ mx: 'auto' }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </ClaimsLayout>
  );
};

ClaimsCreate.defaultProps = {
  updateMode: false,
  control: null,
  index: null,
  update: null,
  value: null
};

ClaimsCreate.propTypes = {
  updateMode: PropTypes.bool,
  control: PropTypes.any,
  index: PropTypes.number,
  update: PropTypes.bool,
  value: PropTypes.any
};

export default ClaimsCreate;

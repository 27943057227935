import PropTypes from 'prop-types';
import { RelatedModel } from './RelatedModel';
import { AttachmentModel } from './AttachmentModel';

export const TicketModel = {
    id: PropTypes.number,
    id_head: PropTypes.number,
    name_user: PropTypes.string,
    text: PropTypes.string,
    client: false,
    created_by: PropTypes.number,
    created_at: PropTypes.number,
    read_at: PropTypes.number,
    _related: [RelatedModel],
    _misc: {
      attachments: PropTypes.arrayOf(PropTypes.shape(AttachmentModel))
    },
    _links: PropTypes.array
  }
;


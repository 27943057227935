import PropTypes from 'prop-types';
import { AvatarModel } from './AvatarModel';
import { UserModel } from './UserModel';

export const ArtistModel = {
  artistType: PropTypes.string,
  avatar: PropTypes.shape(AvatarModel),
  banner: PropTypes.shape(AvatarModel),
  bio: PropTypes.string,
  broadcasterId: PropTypes.number,
  categories: PropTypes.any,
  completedChallenges: PropTypes.array,
  createdAt: PropTypes.string,
  forwardTipsToDefaultEventId: PropTypes.number,
  genreDetails: PropTypes.array,
  handle: PropTypes.string,
  headline: PropTypes.string,
  hometownCity: PropTypes.string,
  hometownCountry: PropTypes.string,
  hometownState: PropTypes.string,
  hospitalityId: PropTypes.number,
  id: PropTypes.number,
  isFollowing: PropTypes.bool,
  isRecommended: PropTypes.bool,
  isVerified: PropTypes.bool,
  musicProId: PropTypes.number,
  ngo: PropTypes.any,
  personalityId: PropTypes.number,
  publicLink: PropTypes.string,
  qrCodeBanner: PropTypes.string,
  qrCodeBannerId: PropTypes.number,
  qrLink: PropTypes.string,
  socialLinks: PropTypes.array,
  stageName: PropTypes.string,
  tipButton: PropTypes.any,
  tipButtonId: PropTypes.number,
  tipsEnabled: PropTypes.bool,
  user: PropTypes.shape(UserModel),
  website: PropTypes.string
};

export default ArtistModel;

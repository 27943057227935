// Packages
import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useNavigate } from 'react-router';
import { AppBar, Box, Link, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { Container } from 'reactstrap';
import parse from 'html-react-parser';

// Relatives
import ClaimsAvatarUser from './ClaimsAvatarUser';
import { BREAKPOINTS } from '../../../const/Breakpoints';
import { PICKLEJAR_DEFAULT_LOGO_IMAGE } from '../../../const/ClaimsTheme';

const ClaimsHeader = props => {
  const { specialHeader, title, hideHandlerOnMobile } = props;
  const isMobile = hideHandlerOnMobile && useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`);
  const navigate = useNavigate();
  const navigateHome = () => {
    navigate('/claims');
  };
  return (
    <AppBar
      position="static"
      className="pj-app-bar"
    >
      <Container>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexGrow: 0
            }}
          >
            <Link
              hidden={! isMobile}
              onClick={navigateHome}
              className="pj-brand-logo"
            >
              <img
                src={PICKLEJAR_DEFAULT_LOGO_IMAGE}
                alt="picklejar logo"
              />
            </Link>
            <Typography
              variant="h5"
              paragraph={false}
            >{parse(title) || ''}</Typography>
          </Box>
          <Box
            component="nav"
            className="navigation-end"
          >
            <ClaimsAvatarUser
              hideHandleOnMobile={isMobile}
              special={specialHeader}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

ClaimsHeader.defaultProps = {
  children: null,
  hideHandlerOnMobile: false,
  isLogged: false,
  user: null,
  specialHeader: false,
  onClickBurger: noop,
  title: ''
};

ClaimsHeader.propTypes = {
  children: PropTypes.node,
  hideHandlerOnMobile: PropTypes.bool,
  isLogged: PropTypes.bool,
  user: PropTypes.object,
  specialHeader: PropTypes.bool,
  onClickBurger: PropTypes.func,
  title: PropTypes.string
};

export default ClaimsHeader;

// Packages
import React, { useContext, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, ListItemAvatar, MenuItem } from '@mui/material';
import get from 'lodash/get';

// Relatives
import ClaimsMenu from '../ClaimsMenu/ClaimsMenu';
import UserContext from '../../../contexts/UserContext';
import { PICKLEJAR_DEFAULT_AVATAR_IMAGE } from '../../../const/ClaimsTheme';

const ClaimsAvatarUser = props => {
  const { params, userNGOs, setUserNGO, userNGOSelected } = useContext(UserContext);
  const { special, hideHandleOnMobile } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const parentRef = useRef(null);
  const handleMenuClose = () => setIsMenuOpen(false);
  const handleMenuOpen = () => setIsMenuOpen(true);
  const avatar = useMemo(() => get(params, 'profile._misc.avatar.large_image'), [params]);
  const handle = useMemo(() => get(params, 'handle'), [params]);

  return (
    <>
      <MenuItem
        ref={parentRef}
        onClick={handleMenuOpen}
      >
        <ListItemAvatar className="avatar-holder me-3">
          <Avatar
            alt={handle ? handle.slice(1, -1).toUpperCase() : 'Anonymous'}
            src={avatar || PICKLEJAR_DEFAULT_AVATAR_IMAGE}
            className="avatar-image"
          />
          {
            ! hideHandleOnMobile &&
            <Box
              sx={{ display: 'flex', flexDirection: 'column' }}
              className="avatar-name"
            >
              <span className="pj-text-primary">{params ? params.handle : 'Anonymous'}</span>
              <span>{get(params, 'virtual_artist_handle')}</span>
            </Box>
          }
        </ListItemAvatar>
      </MenuItem>

      <ClaimsMenu
        specialMenu={special}
        isOpen={isMenuOpen}
        parentRef={parentRef}
        ngos={userNGOs}
        ngoSelected={userNGOSelected}
        onClose={handleMenuClose}
        onSelectNGO={setUserNGO}
        isMobile={hideHandleOnMobile}
      />
    </>
  );
};

ClaimsAvatarUser.defaultProps = {
  special: true,
  hideHandleOnMobile: false
};

ClaimsAvatarUser.propTypes = {
  special: PropTypes.bool,
  hideHandleOnMobile: PropTypes.bool
};

export default ClaimsAvatarUser;

import { createTheme } from '@mui/material';
import { BREAKPOINTS } from './Breakpoints';

// Const
export const PICKLEJAR_DEFAULT_LOGO_IMAGE = '/images/logos/pj-brandmark-logo.svg';
export const PICKLEJAR_DEFAULT_BACKGROUND_IMAGE = '/images/waves-bg-md.svg';
export const PICKLEJAR_DEFAULT_PDF_IMAGE = '/images/icons/media/pdf_file_icon.svg';
export const PICKLEJAR_DEFAULT_EDIT_ICON = '/images/icons/edit_icon.svg';
export const PICKLEJAR_DEFAULT_AVATAR_IMAGE = '/images/avatarDefault.svg';
export const PICKLEJAR_EVENTS_DEFAULT_IMAGE = '/images/EventsDefault-min.png';
// Styles

/**
 * Color variants
 */
export const picklejarPrimaryColor = '#FF9E18';
export const picklejarSecondaryColor = '#66C22B';
export const picklejarGradientPrimary = 'linear-gradient(90deg, rgba(246,145,30,1) 0%, rgba(195,76,39,1) 100%)';
export const picklejarTextColorDark = '#000000';
export const picklejarTextColorLight = '#ffffff';
export const picklejarTextColorMedium = '#7e7e7e';
export const picklejarTextColorGray = '#f3f3f3';
export const picklejarLightBackground = '#FFFFFF';
export const picklejarBorderColor = '#dedede';
export const picklejarMargin = '1.5rem';
export const picklejarBorderRadius = '1.5rem';
/**
 * Fonts
 */
export const picklejarFontFamily = 'Lato, Roboto, Montserrat, sans-serif';
export const picklejarFontSize = 16;
export const picklejarFontSizeLarge = 'clamp(1.15rem, 2vw, 1.25rem)';
/**
 * Avatar, Images, Icons
 */
export const picklejarIconButtonSize = '64px';
export const picklejarAvatarSize = '40px';
export const picklejarAvatarSizeLg = picklejarIconButtonSize;
export const picklejarBorderInputColor = '#EDEDED';
export const picklejarBoxShadow = '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)';
// Messages
export const picklejarMessagePadding = '0.67em';
export const picklejarMessageBorderRadius = '.5rem';
export const picklejarInputBorderBottomWidth = '2px';

export const ThemeBreakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
});

export const ClaimsTheme = createTheme({
  breakpoints: BREAKPOINTS,
  typography: {
    fontFamily: picklejarFontFamily,
    fontSize: picklejarFontSize,
    fontWeightLight: 100,
    fontWeightRegular: 100,
    fontWeightBold: 800,
    h1: {
      fontSize: '2.25rem',
      marginTop: '0.67em',
      marginBottom: '0.67em',
      letterSpacing: '0.025em',
      fontWeight: '100'
    },
    h2: {
      fontSize: '1.97rem',
      marginTop: '0.50em',
      marginBottom: '0.50em',
      letterSpacing: '0.025em'
    },
    h3: {
      fontSize: '1.72em',
      marginTop: '0.40em',
      marginBottom: '0.40em',
      letterSpacing: '0.025em'
    },
    h4: {
      fontSize: '1.5rem',
      marginTop: '0.30em',
      marginBottom: '0.30em',
      letterSpacing: '0.025em'
    },
    h5: {
      fontSize: '1.31rem',
      marginTop: '0.20em',
      marginBottom: '0.20em',
      letterSpacing: '0.025em'
    },
    h6: {
      fontSize: '1.15rem',
      marginTop: '0.10em',
      marginBottom: '0.10em'
    },
    body1: {
      marginTop: '0.67em',
      marginBottom: '0.67em',
      color: picklejarTextColorDark
    },
    body2: {
      marginTop: '0.67em',
      marginBottom: '0.67em',
      color: picklejarTextColorLight
    },
    labels: {
      fontSize: '.9rem',
      marginTop: '0.25',
      marginBottom: '0.25',
      color: picklejarTextColorMedium,
      fontWeight: 400
    },
    messageBody: {
      marginTop: picklejarMessagePadding,
      marginBottom: picklejarMessagePadding,
      paddingLeft: picklejarMessagePadding,
      paddingRight: picklejarMessagePadding,
      display: 'block'
    },
    small: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '0.8em',
      marginTop: '0.5em',
      marginBottom: '0.5em'
    }
  },
  palette: {
    primary: {
      main: picklejarPrimaryColor
    },
    secondary: {
      main: picklejarSecondaryColor
    }
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          minHeight: picklejarIconButtonSize,
          borderRadius: `${picklejarBorderRadius} !important`,
          marginTop: picklejarMargin,
          marginBottom: picklejarMargin,
          overflow: 'hidden'
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '34px'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: picklejarIconButtonSize,
          fontWeight: 'bold',
          backgroundColor: picklejarLightBackground,
          width: '100%',
          borderRadius: picklejarBorderRadius,
          background: 'transparent',
          color: 'inherit',
          textTransform: 'uppercase',

          'p': {
            margin: 0
          }
        },
        expandIconWrapper: {
          color: picklejarPrimaryColor
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          '.MuiIconButton-root': {
            width: '36px',
            height: '36px'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: picklejarLightBackground
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            width: '14px',
            height: '14px'
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        circular: {
          border: `solid 2px ${picklejarPrimaryColor}`,
          borderRadius: '50%',
          padding: '4px',
          height: picklejarAvatarSize,
          width: picklejarAvatarSize,
          overflow: 'hidden',
          '&.border': {
            border: 'none'
          },
          'img': {
            borderRadius: '50%'
          }
        },
        root: {
          'img': {
            background: 'transparent !important',
            backgroundColor: 'transparent !important',
            objectFit: 'cover'
          }
        }
      }
    },
    MuiBox: {
      variants: [
        {
          props: { variant: 'supportMessage' },
          style: {
            padding: picklejarMessagePadding,
            marginLeft: picklejarMessagePadding,
            marginRight: picklejarMessagePadding,
            color: picklejarTextColorLight,
            borderRadius: picklejarMessageBorderRadius,
            border: `solid thin ${picklejarPrimaryColor}`,
            '.pj-ticket-content-attachment': {
              '.pj-attachment-header': {
                fontSize: '.85rem'
              }
            }
          }
        },
        {
          props: { variant: 'supportMessage', color: 'primary' },
          style: {
            borderColor: picklejarPrimaryColor,
            color: picklejarTextColorMedium
          }
        },
        {
          props: { variant: 'supportMessage', color: 'secondary' },
          style: {
            backgroundColor: picklejarLightBackground,
            color: picklejarTextColorDark,
            border: `solid thin ${picklejarTextColorDark}`
          }
        }
      ]
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            background: picklejarGradientPrimary
          }
        }
      ],
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          maxWidth: '500px'
        },
        endIcon: {
          '.img': {
            minWidth: '30px',
            bottom: '3px',
            position: 'relative'
          }
        },

        /* root: {
         containedSecondary: {
         color: picklejarTextColorLight
         },
         fontSize: '1rem',
         '&:hover': {
         textDecoration: 'none',
         backgroundColor: 'inherit',
         color: primaryColorDark
         }
         }, */
        sizeLarge: {
          padding: '0.5rem 1.5rem',
          borderRadius: '12px',
          borderWidth: 'medium',
          fontSize: 'clamp(1rem, 2vw, 1.25rem)',
          '&:hover': {
            borderWidth: 'medium'
          },
          minHeight: { sx: '50px', sm: '70px' }
        },
        text: {
          fontWeight: 'normal',
          textAlign: 'center',
          justifySelf: 'center',
          alignSelf: 'center'
        }
      }
    },
    MuiButtonBase: {},
    MuiCard: {
      styleOverrides: {
        root: {
          color: picklejarTextColorDark,
          fontWeight: 'normal',
          fontSize: '1rem'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          backgroundColor: picklejarPrimaryColor,
          color: picklejarTextColorDark,
          textAlign: 'center',
          '&.justify-content-center': {
            justifyContent: 'center'
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          position: 'relative',
          borderRadius: '24px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        filledPrimary: {
          background: picklejarGradientPrimary
        }
      }
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          borderTop: `solid thin ${picklejarBorderColor}`,
          textAlign: 'left',
          fontSize: '1rem',
          backgroundColor: picklejarLightBackground
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          display: `flex`
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '.MuiFormControl-root.pj-light-underline .MuiInputBase-root.MuiInputBase-colorPrimary.MuiInput-underline:before': {
            borderColor: picklejarPrimaryColor
          },
          '.MuiFormControl-root.pj-light-underline label.MuiFormLabel-root': {
            color: picklejarTextColorDark
          },
          '.MuiTypography-root': {
            color: picklejarTextColorDark
          }
        }
      }
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: picklejarGradientPrimary
          }
        }
      ],
      styleOverrides: {
        root: {
          width: picklejarIconButtonSize,
          height: picklejarIconButtonSize,
          padding: '0',
          justifySelf: 'center',
          alignSelf: 'center'
        }
      }
    },
    MuiInput: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          fontSize: picklejarFontSizeLarge,
          '&:before, &:hover:before, &:focus, &:hover:focus, &:active, &:hover:active': { borderBottomWidth: picklejarInputBorderBottomWidth },
          '&:hover:not(.Mui-disabled, .Mui-error):before, &:focus:not(.Mui-disabled, .Mui-error):before, &:active:not(.Mui-disabled, .Mui-error):before': { borderBottomWidth: picklejarInputBorderBottomWidth }
          /* '&.MuiInputBase-colorPrimary': {
           borderColor: picklejarPrimaryColor,
           '&:before, &:not(.Mui-disabled):before': {
           borderColor: picklejarPrimaryColor
           },
           '.phone-input': {
           paddingBottom: '.5rem',
           borderColor: picklejarLightBackground,
           '.MuiInputBase-colorPrimary': {
           borderColor: picklejarPrimaryColor,
           '&:before, &:not(.Mui-disabled):before': {
           borderColor: picklejarLightBackground
           }
           }
           }
           } */
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        colorPrimary: {
          color: picklejarTextColorDark,
          borderColor: picklejarBorderInputColor
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {}
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '.MuiBox-root': {
            '&.supportMessage': {
              width: '100%',
              padding: picklejarMessagePadding,
              marginLeft: picklejarMessagePadding,
              marginRight: picklejarMessagePadding,
              marginTop: picklejarMessagePadding,
              color: picklejarTextColorLight,
              borderRadius: picklejarMessageBorderRadius,
              border: `solid thin ${picklejarPrimaryColor}`,
              '&.primary': {
                borderColor: picklejarPrimaryColor,
                color: picklejarTextColorMedium
              },
              '&.secondary': {
                backgroundColor: picklejarLightBackground,
                color: picklejarTextColorDark,
                border: `solid thin ${picklejarTextColorDark}`,
                textAlign: 'right'
              }
            }
          }
        }
      },
      variants: [
        {
          props: { variant: 'supportMessage' },
          style: {
            padding: picklejarMessagePadding,
            marginLeft: picklejarMessagePadding,
            marginRight: picklejarMessagePadding,
            color: picklejarTextColorLight,
            borderRadius: picklejarMessageBorderRadius,
            border: `solid thin ${picklejarPrimaryColor}`
          }
        },
        {
          props: { variant: 'supportMessage', color: 'primary' },
          style: {
            backgroundColor: picklejarPrimaryColor,
            color: picklejarTextColorLight
          }
        },
        {
          props: { variant: 'supportMessage', color: 'secondary' },
          style: {
            backgroundColor: picklejarLightBackground,
            color: picklejarTextColorDark,
            border: `solid thin ${picklejarTextColorDark}`
          }
        }
      ]
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          position: 'relative',
          '&.avatar-holder': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '.avatar-name': {
              maxWidth: '150px',
              marginLeft: '12px',

              'span': {
                whiteSpace: 'nowrap',
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              },

              'select': {
                padding: '0px 32px 0px 0',
                border: 'none',
                backgroundColor: 'transparent',
                backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
                backgroundPosition: 'right 0.5rem center',
                color: 'white',
                cursor: 'pointer',

                '&:focus': {
                  boxShadow: 'none'
                }
              }
            },
            '&.justify-content-start': {
              justifyContent: 'start'
            }
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          position: 'relative'
        }
      },
      variants: [
        {
          props: { variant: 'supportMessage' },
          style: {
            padding: picklejarMessagePadding,
            marginLeft: picklejarMessagePadding,
            marginRight: picklejarMessagePadding,
            color: picklejarTextColorLight,
            borderRadius: picklejarMessageBorderRadius,
            border: `solid thin ${picklejarPrimaryColor}`
          }
        },
        {
          props: { variant: 'supportMessage', color: 'primary' },
          style: {
            backgroundColor: picklejarPrimaryColor,
            color: picklejarTextColorLight
          }
        },
        {
          props: { variant: 'supportMessage', color: 'secondary' },
          style: {
            backgroundColor: picklejarLightBackground,
            color: picklejarTextColorDark,
            border: `solid thin ${picklejarTextColorDark}`
          }
        }
      ]
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '.MuiContainer-root': {
            width: '80%',
            maxHeight: '80%',
            left: '50%',
            top: '50%',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            border: 'none',
            backgroundColor: '#fff',
            transform: 'translate(-50%, -50%)',
            boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
          },
          '.modal-header': {
            padding: '1rem 1rem .5rem 1rem',
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            borderBottom: 'solid thin #e4e4e4',

            '.title': {
              flex: 'auto'
            }
          },
          '.modal-body': {
            marginBottom: '1rem',
            padding: '1rem',
            overflow: 'auto'
          }
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true
      },
      styleOverrides: {
        root: {
          marginBottom: '1.5rem',
          '&.pj-text-center': {
            'input': {
              textAlign: 'center'
            }
          },
          '&.text-left': {
            'input': {
              textAlign: 'left'
            }
          },
          '&.text-right': {
            'input': {
              textAlign: 'right'
            }
          },
          '+ .MuiFormControl-root': {
            marginLeft: '1rem'
          },
          '.MuiInputLabel-standard': {
            '&.MuiInputLabel-root': {
              top: '-10px',
              color: '#fff',
              opacity: '0.3',

              '&.MuiInputLabel-shrink': {
                opacity: 1
              }
            }
          }
        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        fullWidth: true
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
        size: 'small'
      },
      'input::placeholder': {
        color: 'red'
      },
      styleOverrides: {
        root: {
          '&.pj-light-underline .MuiInput-underline': {
            borderColor: `${picklejarLightBackground} !important`,
            '&:before, &:not(.Mui-disabled):before': {
              borderColor: picklejarLightBackground
            }
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: picklejarPrimaryColor
        },
        colorSecondary: {
          color: picklejarSecondaryColor
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: picklejarTextColorDark,
          textAlign: 'left'
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '90%',
          bottom: '80px',
          [ThemeBreakpoints.breakpoints.up('sm')]: {
            bottom: '80px'
          },
          [ThemeBreakpoints.breakpoints.up('md')]: {
            bottom: '20px'
          },
          '.MuiPaper-root.MuiAlert-root': {
            /* width: 'calc(90% - calc(33% + 48px))',
             margin: 0 */
            width: 'calc(100% - 96px)',
            [ThemeBreakpoints.breakpoints.up('xs')]: {
              width: 'auto'
            }
          }
        }
      }
    },
    MuiStack: {
      styleOverrides: {
        root: {
          position: 'relative'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.message-date': {
            fontSize: '10px'
          }
        }
      },
      variants: [
        {
          props: { variant: 'supportBody' },
          style: {
            display: 'block',
            marginBottom: '.25rem'
          }
        },
        {
          props: { variant: 'supportBody', color: 'support' },
          style: {
            color: `${picklejarTextColorLight} !important`
          }
        },
        {
          props: { variant: 'supportBody', color: 'user' },
          style: {
            color: picklejarTextColorDark
          }
        }
      ]
    }
  }
});

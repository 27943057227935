// Packages
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { get } from "lodash";
import { FormHelperText, Grid, Typography } from "@mui/material";

// Relatives
import AppContext from "../../../contexts/AppContext";
import UserContext from "../../../contexts/UserContext";
import NotificationContext from "../../../contexts/NotificationContext";
import { PICKLEJAR_DEFAULT_AVATAR_IMAGE } from "../../../const/ClaimsTheme";
import http from "../../../services/api/http";
import ImageCropInput from "../../../components/ImageCropInput";

export const UserProfileImageForm = props => {
  const { loadingAvatar, setLoadingAvatar, canEdit, defaultPreviewUrl } = props
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const { notificationError } = useContext(NotificationContext);
  const [previewUrl, setPreviewUrl] = useState(defaultPreviewUrl);
  const userProfileForm = useForm({
    defaultValues: {
      image: null
    }
  });
  const { control, setError } = userProfileForm;
  /**
   * Handlers
   */
  const updateProfilePicture = async (callbackLoading, input, fileData) => {
    if (!token) {
      return;
    }

    const formData = new FormData();
    formData.append('image', fileData.file);
    setLoadingAvatar(true);
    const profilePictureResponse = await http(apiHost, token.accessToken, token.tokenType, { 'Content-Type': 'multipart/form-data' })
    .post('/api/web/v1/auth/upload-profile-picture', formData)
    .catch(err => {
      notificationError(err.message);
    });

    setLoadingAvatar(false);
    if (!profilePictureResponse || !profilePictureResponse.data) {
      notificationError('Something went wrong. please try again');
      return;
    }
    let message = null;
    const { success, data } = get(profilePictureResponse, 'data', {});
    if (success) {
      if (data.files) {
        const { files: [error] } = data;
        message = error.error;
        notificationError(message);
        return;
      }
      setPreviewUrl(fileData.croppedImageUrl);
    } else {
      if (!data) {
        notificationError('Something went wrong. please try again');
        return;
      }

      Object.keys(data).forEach(field => {
        setError(field, { type: 'required', message: get(data, `${field}.0`) });
      });
    }
  };
  const onUpload = callbackLoading => (name, data) => updateProfilePicture(callbackLoading, name, data);
  /**
   * Effects
   */
  useEffect(() => {
    setPreviewUrl(defaultPreviewUrl)
  }, [defaultPreviewUrl]);
  return (
    <Grid
      container
      component="form"
      autoComplete="off"
      className="pj-user-profile__form"
      maxWidth="md"
    >
      <Grid
        item
        xs={12}
      >

        <Controller
          name="image"
          control={control}
          render={({ field, fieldState }) => (
            <div className="image-crop-input MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <ImageCropInput
                name={field.name}
                onUpload={onUpload(setLoadingAvatar)}
                aspect={1}
                showPreview
                previewUrl={previewUrl}
                loading={loadingAvatar}
                canEdit={canEdit}
              />
              <FormHelperText>
                {fieldState.error && (
                  <Typography
                    variant="p"
                    color="error"
                  >
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </Grid>
    </Grid>
  )
}

UserProfileImageForm.defaultProps = {
  canEdit: false,
  loadingAvatar: false,
  setLoadingAvatar: null,
  onSubmit: null,
  defaultPreviewUrl: PICKLEJAR_DEFAULT_AVATAR_IMAGE
}

UserProfileImageForm.propTypes = {
  canEdit: PropTypes.bool,
  loadingAvatar: PropTypes.bool,
  setLoadingAvatar: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultPreviewUrl: PropTypes.string
}
export default UserProfileImageForm;

/* eslint-disable camelcase */
// Packages
import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const ClaimsChips = props => {
  const { status, isApproved, isRejected } = props;

  const getType = status => {
    switch (status.toLowerCase()) {
      case 'open' || 'wait': {
        return 'info';
      }

      case 'answered': {
        return 'warning';
      }

      case 'closed': {
        if (isRejected === true) {
          return 'error';
        }

        if (isApproved === true) {
          return 'success';
        }

        return 'success';
      }

      default:
        return 'info';
    }
  };
  return (
    status !== null &&
    <Stack
      direction="row"
      justifyContent="end"
      alignItems="center"
      spacing={1}
      className="chip"
    >
      <Chip
        label={status}
        color={getType(status)}
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      />
    </Stack>
  );
};

ClaimsChips.defaultProps = {
  status: null
};

ClaimsChips.propTypes = {
  status: PropTypes.string,
  isApproved: PropTypes.bool.isRequired,
  isRejected: PropTypes.bool.isRequired
};

export default ClaimsChips;
